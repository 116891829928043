import { createContext } from "react";
import packageJson from "../../package.json";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { AdminHome, Main, PageNotFound } from "app/pages";
import { Legislation } from "app/pages/legislation";
import { QueryClient, QueryClientProvider } from "react-query";
import { Layout } from "app/page-layout";

import { Auth0Provider } from "@auth0/auth0-react";
import { TermsAndConditions } from "app/pages/terms";
import { Disclaimer } from "app/pages/disclaimer";
import { AppProvider, useAppContext } from "./AppContext";
import { AdminLegislation } from "./pages/admin/view-legislation";
import { SignInScreen } from "./components/sign-in-screen/sign-in-screen";
import { AdminLayout } from "./admin-layout";
import { ToastProvider } from "./components/toast";

console.log(`Build Version:`, packageJson.version);

const queryClient = new QueryClient();

export const {
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_CLIENT_ID,
  REACT_APP_ENV = "dev",
} = process.env;

console.log({
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_CLIENT_ID,
  REACT_APP_ENV,
});

export const API_ENDPOINTS = {
  dev: "https://legislation-tracker-api-dev.philip-134.workers.dev",
  demo: "https://legislation-tracker-api-demo.philip-134.workers.dev",
  prod: "https://legislation-tracker-api-prod.philip-134.workers.dev",
};

export const ChatHistoryContext = createContext(null);
export const ChatViewContext = createContext(null);
export const FilterViewContext = createContext(null);

export const USERNAME = "User"; // needs to be replaced with auth
export const BOT_USER_NAME = "A I";

const REDIRECT_URI = window.location.origin;

const AppContent = () => {
  const { backgroundStyle } = useAppContext();

  return (
    <ToastProvider>
      <Auth0Provider
        domain={REACT_APP_AUTH_DOMAIN}
        clientId={REACT_APP_CLIENT_ID}
        authorizationParams={{
          redirect_uri: REDIRECT_URI,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <SignInScreen />
          <Routes>
            <Route
              path="/"
              element={
                <div className={backgroundStyle}>
                  <Outlet />
                </div>
              }
            >
              <Route
                index
                element={
                  <Layout>
                    <Main />
                  </Layout>
                }
              />
              <Route
                path="/legislation"
                element={
                  <Layout>
                    <Main />
                  </Layout>
                }
              />
              <Route
                path="/legislation/:legislationId"
                element={
                  <Layout>
                    <Legislation />
                  </Layout>
                }
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/disclaimer" element={<Disclaimer />} />

              <Route path="/admin" element={<Navigate to="/admin/draft" />} />
              <Route
                path="/admin/draft"
                element={
                  <AdminLayout>
                    <AdminHome />
                  </AdminLayout>
                }
              />
              <Route
                path="/admin/published"
                element={
                  <AdminLayout>
                    <AdminHome />
                  </AdminLayout>
                }
              />
              <Route
                path="/admin/archived"
                element={
                  <AdminLayout>
                    <AdminHome />
                  </AdminLayout>
                }
              />
              <Route path="/admin/:id" element={<AdminLegislation />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </QueryClientProvider>
      </Auth0Provider>
    </ToastProvider>
  );
};

export const App = () => {
  return (
    <AppProvider>
      <AppContent />
    </AppProvider>
  );
};
