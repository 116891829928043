import React from "react";
import { LegislationList } from "app/components";

export const Main = () => {
  return (
    <div className="">
      <LegislationList />
    </div>
  );
};
