import { API_ENDPOINTS, REACT_APP_ENV } from "app/app";
import { useCallback, useState, useRef } from "react";
import { useAppContext } from "app/AppContext";

export const useStreamedData = () => {
  const [data, setData] = useState(``);
  const [conversationId, setConversationId] = useState();
  const [taskId, setTaskId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(new AbortController());
  const { id: contextId } = useAppContext();

  const handleSubmit = useCallback(
    async (payload) => {
      setIsLoading(true);
      setIsDone(false);
      setError(null);

      try {
        const response = await fetch(
          `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/agent_task/stream/?context=${contextId}`,
          {
            method: "POST",
            headers: {
              Accept: "text/event-stream",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
            signal: abortControllerRef.current.signal,
          },
        );

        setConversationId(response.headers.get("Conversation_id"));
        setTaskId(response.headers.get("Task_id"));

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const processStream = async (result = {}) => {
          const chunk = decoder.decode(result.value || new Uint8Array(), {
            stream: !result.done,
          });
          setData((prevData) => `${prevData}${chunk}`);

          if (result.done) return;

          const nextResult = await reader.read();
          await processStream(nextResult);
        };

        const initialResult = await reader.read();
        await processStream(initialResult);

        setIsLoading(false);
        setTaskId(null);

        setData(``);
        setIsDone(true);
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(err);
        }
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [contextId],
  );

  const abortFetch = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      // Create a new instance for the next fetch
      abortControllerRef.current = new AbortController();
    }
  };

  return {
    getStream: handleSubmit,
    cancel: abortFetch,
    data: { message: data, conversationId, taskId },
    isLoading,
    isDone,
    error,
  };
};
