import axios from "axios";
import { REACT_APP_ENV, API_ENDPOINTS } from "app";

const uploadFileToS3 = async (fileData, initialResponse) => {
  const formData = new FormData();
  Object.entries(initialResponse.s3_upload_url_fields).forEach(
    ([key, value]) => {
      formData.append(key, value);
    },
  );

  formData.append("file", fileData);

  const response = await axios({
    method: "post",
    url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/upload/${initialResponse.s3_upload_url}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

const triggerEnrich = async (initialResponse, context) => {
  return await axios({
    method: "PATCH",
    url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/enrich/legislation_document/${initialResponse.id}/?context=${context}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      summary: initialResponse.summary,
      trigger_summarization_pipeline: true,
    }),
  });
};

export const saveLegislationQuery = ({ context, isNew }) => {
  return async ({ payload, fileData }) => {
    const saveResponse = await axios({
      method: isNew ? "POST" : "PATCH",
      url: isNew
        ? `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document/?context=${context}`
        : `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document/${payload.id}/?context=${context}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(payload),
    });

    try {
      if (isNew && saveResponse.data && fileData) {
        await uploadFileToS3(fileData, saveResponse.data);
        await triggerEnrich(saveResponse.data, context);
      }
    } catch (e) {
      console.log(e);
    }

    return saveResponse.data;
  };
};

export const deleteLegislationQuery = ({ context, id }) => {
  return async () => {
    const saveResponse = await axios({
      method: "DELETE",
      url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document/${id}/?context=${context}`,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return saveResponse.data;
  };
};

export const fetchDocumentById = ({ id, context }) => ({
  queryKey: `legislation_${id}`,
  queryFn: () => {
    return axios({
      method: `get`,
      url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document/${id}?context=${context}`,
    });
  },
});

export const fetchAllDocuments = ({
  context,
  staleTime = Infinity,
  status,
}) => ({
  queryKey: [`legislation_documents`, status],
  queryFn: () => {
    return axios({
      method: `get`,
      url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_documents_all?context=${context}&internal_status=${status}`,
    });
  },
  staleTime,
});
