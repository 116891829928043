import React from "react";

export const Tag = ({ label, className }) => {
  return (
    <div className="flex flex-wrap">
      <span
        className={`bg-[#EFF6FF] mr-2 mt-2 text-[13px] px-[8px] py-[2px] rounded-full border border-[#E6ECFF] whitespace-nowrap ${className}`}
      >
        {label}
      </span>
    </div>
  );
};
