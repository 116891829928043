import React, { useContext, useEffect, useState, useCallback } from "react";
import classNames from "classnames/bind";
import { ChatViewContext, REACT_APP_ENV } from "app";
import BeatLoader from "react-spinners/BeatLoader";
import { useAuth0 } from "@auth0/auth0-react";

import { BOT_USER_NAME, ChatHistoryContext } from "app";
import { ChatMessages } from "./chat-messages";
import { SignInBlocker } from "./sign-in-blocker";
import staticAnswers from "./static-answers.json";
import { useAutoScroll } from "./use-autoscroll";
import { ChatInput } from "./chat-input";
import { TIMEOUT_MESSAGE } from "./use-timeout-message";
import { useThinkingMessages } from "./use-thinking-messages";
import { useStreamedData } from "./use-streamed-data";
import md5 from "md5";
import { Switcher } from "../switcher/switcher";

const cx = classNames.bind();
const SIMPLE = "simple";
const ADVANCED = "advanced";
export const ChatBot = () => {
  const { chatHistory, addMessageToChat } = useContext(ChatHistoryContext);
  const [chatInputValue, setChatInputValue] = useState("");
  const [chatComplexity, setChatComplexity] = useState(SIMPLE);
  const [isFauxLoading, setIsFauxLoading] = useState(false);
  const { showChat } = useContext(ChatViewContext);

  const { getStream, data, isLoading, cancel } = useStreamedData();

  const { isAuthenticated, user } = useAuth0();

  const { chatContainerRef, handleScroll } = useAutoScroll();

  const needsToSignIn =
    !isAuthenticated &&
    chatHistory.filter(({ author, message }) => {
      if (message === TIMEOUT_MESSAGE) {
        return false;
      }

      return author === BOT_USER_NAME;
    }).length;

  const sendChatMessage = useCallback(
    (message, keepInput) => {
      if (!keepInput) setChatInputValue("");
      handleScroll(false);

      return addMessageToChat(message);
    },
    [setChatInputValue, handleScroll, addMessageToChat],
  );

  const checkDictionary = useCallback(
    ({ message: lastHumanMessageInChat }) => {
      const foundStaticResponse = staticAnswers.find(
        ({ message }) => message === lastHumanMessageInChat,
      );

      if (!foundStaticResponse) return false;

      setIsFauxLoading(true);
      const sendFauxMessage = async () => {
        await new Promise((r) => setTimeout(r, 7000));

        setIsFauxLoading(false);
        sendChatMessage({
          taskId: md5(foundStaticResponse.answer),
          message: foundStaticResponse.answer,
          author: BOT_USER_NAME,
          type: "chat",
          isResponse: true,
        });
      };

      return sendFauxMessage();
    },
    [sendChatMessage],
  );

  useEffect(() => {
    const { message } = chatHistory[chatHistory.length - 1] || {};
    if (data.message.length && message !== data.message) {
      sendChatMessage(
        { ...data, author: BOT_USER_NAME, isResponse: true },
        true,
      );
    }
  }, [data, sendChatMessage, chatHistory]);

  useEffect(() => {
    const { author, message, type, document_summary_id } =
      chatHistory.at(-1) || {};
    const { conversationId } =
      chatHistory.find(({ conversationId }) => conversationId) || {};

    if (author !== BOT_USER_NAME && type === "chat") {
      const checkingFauxResponse = checkDictionary({ message });

      if (!checkingFauxResponse) {
        getStream({
          question_text: message,
          conversation_id: conversationId,
          agent_type: chatComplexity,
          ...(document_summary_id ? { document_summary_id } : {}),
        });
      }
    }
  }, [getStream, chatComplexity, chatHistory, showChat, user, checkDictionary]);

  const thinkingMessage = useThinkingMessages({
    isLoading: (isLoading && !data.message) || isFauxLoading,
  });

  return (
    <div className={cx("relative flex flex-col h-full")}>
      {REACT_APP_ENV === "dev" && (
        <div
          className={`flex items-center space-x-[8px] justify-end text-[11px] mb-[8px]`}
        >
          <p>Advanced model</p>
          <Switcher
            onChange={() =>
              setChatComplexity(chatComplexity === SIMPLE ? ADVANCED : SIMPLE)
            }
            checked={chatComplexity === ADVANCED}
          />
        </div>
      )}
      <div
        ref={chatContainerRef}
        className="md:rounded-t-2xl bg-[rgba(255,255,255,0.2)] output flex-1 no-scrollbar overflow-scroll pb-[16px] md:pb-0"
        onWheel={() => {
          handleScroll(true);
        }}
        onTouchMove={() => {
          handleScroll(true);
        }}
      >
        <div className="h-full p-[24px] flex flex-col">
          {chatHistory.map((message, i) => (
            <ChatMessages
              index={i}
              message={message}
              key={message.message + i}
              chatLength={chatHistory.length}
              sendChatMessage={sendChatMessage}
            />
          ))}

          {needsToSignIn ? <SignInBlocker /> : null}

          {isLoading ? (
            <div className="flex justify-end items-center pt-[24px] pb-[12px]">
              <BeatLoader size={8} />
              <p
                data-testId="thinking-message"
                className="text-[14px] pl-[8px]"
              >
                {thinkingMessage}
              </p>
            </div>
          ) : (
            <div className="min-h-[24px]"></div>
          )}
        </div>
      </div>
      <ChatInput
        cancel={cancel}
        chatDisabled={needsToSignIn}
        sendChatMessage={sendChatMessage}
        chatInputValue={chatInputValue}
        setChatInputValue={setChatInputValue}
        isLoading={isLoading}
      />
    </div>
  );
};
