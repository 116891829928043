import { useEffect, useState } from "react";
import { Button, Input } from "@pentatonic/components";
import {
  useDeleteDocumentTest,
  useGetTestResults,
  usePostDocumentTest,
  useRerunTest,
} from "app/data/admin-tests";
import { useParams } from "react-router-dom";
import { RunTest } from "./run-test-ui";
import { ClipLoader } from "react-spinners";
import { TextDisplay } from "../text-display";
import { Tag } from "../tag";

export const standardQuestions = [
  "Can you summarise this legislation?",
  "What jurisdiction does this legislation apply to?",
];

export const AdminTests = () => {
  const { id: document_id } = useParams();
  const [tests, setTests] = useState([]);
  const { data: testResults, refetch: refetchDocumentTests } =
    useGetTestResults(document_id);
  const {
    rerun,
    isLoading,
    data: results,
    hasBeenRan,
  } = useRerunTest({ document_id });
  const { runTests } = usePostDocumentTest({
    onSuccess: ({ data }) => {
      if (standardQuestions.includes(data.question_text)) {
        rerun(data.test_id);
      }

      refetchDocumentTests(data);
    },
  });

  const { deleteTest } = useDeleteDocumentTest({
    onSuccess: refetchDocumentTests,
  });

  useEffect(() => {
    refetchDocumentTests();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center space-y-large mt-xxxx-large">
        <ClipLoader />
        <h3 className="text-4xl">Running Tests</h3>
        <span className="text-slate-500">
          Please wait whilst testing is in progress.
        </span>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col ${!hasBeenRan && "items-center"} p-xxx-large`}
    >
      {hasBeenRan ? (
        <>
          <h3 className="text-4xl mb-xxx-large">Test Results</h3>
          <div className="mb-xx-large">
            {results?.tests
              .filter(({ answer }) => Boolean(answer))
              .map(({ test_id, question_text, answer }) => {
                return standardQuestions.includes(question_text) ? (
                  <div key={test_id} className="mb-xx-large">
                    <span className="flex items-center">
                      <h4 className="font-medium">{question_text}</h4>
                    </span>
                    <TextDisplay className="text-slate-700" text={answer} />
                  </div>
                ) : null;
              })}

            {results?.tests
              .filter(({ answer }) => Boolean(answer))
              .map(({ test_id, question_text, answer }) => {
                return !standardQuestions.includes(question_text) ? (
                  <div key={test_id} className="mb-xx-large">
                    <span className="flex items-center">
                      <Tag label="Custom" />{" "}
                      <h4 className="font-medium">{question_text}</h4>
                    </span>
                    <TextDisplay className="text-slate-700" text={answer} />
                  </div>
                ) : null;
              })}
          </div>
        </>
      ) : (
        <RunTest />
      )}

      <div>
        <Button
          secondary
          label="Run tests"
          iconGlyph="labs"
          onClick={() => {
            const missingStandardTests = standardQuestions.filter(
              (standardQuestion) => {
                return !testResults.tests.find(
                  ({ question_text }) => question_text === standardQuestion,
                );
              },
            );

            if (missingStandardTests.length) {
              runTests(
                missingStandardTests.map((question_text) => ({
                  question_text,
                  document_id,
                })),
              );
            }

            testResults.tests.forEach(({ test_id }) => rerun(test_id));
          }}
        />
      </div>
      <hr className="text-slate-200 my-xx-large" />
      <div className="flex flex-col items-center space-y-regular w-full px-large">
        <div className="w-full flex flex-col items-start space-y-4">
          {Boolean(testResults?.tests.length) && (
            <h3 className="text-3xl">Custom Test Questions</h3>
          )}
          <div className="divide-y divide-slate-200 w-full">
            {testResults?.tests.map(({ question_text, test_id }) =>
              standardQuestions.includes(question_text) ? null : (
                <p key={test_id} className="">
                  <span className="flex items-center justify-between">
                    {question_text}{" "}
                    <div className="flex">
                      <Button
                        minimal
                        secondary
                        iconGlyph="delete"
                        iconClassName="!text-3xl text-slate-200 hover:text-red"
                        onClick={() => deleteTest(test_id)}
                      />
                    </div>
                  </span>
                </p>
              ),
            )}
          </div>
        </div>
        <div className="w-full flex flex-col items-start space-y-4">
          {tests.map((test, i) => (
            <Input
              key={`${i}`}
              label={`Question`}
              value={test}
              containerClassName="w-full"
              onChange={(e) => {
                if (i >= 0 && i < tests.length) {
                  setTests((items) => [
                    ...items.slice(0, i),
                    e.target.value,
                    ...items.slice(i + 1),
                  ]);
                }
              }}
            />
          ))}
          <div className="flex justify-between w-full">
            <Button
              minimal
              secondary
              iconGlyph="add"
              label="Add Question"
              onClick={() => setTests((currentTests) => [...currentTests, ""])}
            />

            {tests.length ? (
              <div className="flex space-x-4">
                <Button secondary label="Cancel" onClick={() => setTests([])} />

                <Button
                  iconGlyph="check"
                  label="Save"
                  onClick={() => {
                    setTests([]);
                    runTests(
                      tests.map((question_text) => ({
                        question_text,
                        document_id,
                      })),
                    );
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
