import { useContext } from "react";
import { Link } from "react-router-dom";
import { Flag } from "app/constants/flag-object";
import { ChatViewContext, ChatHistoryContext } from "app";
import classNames from "classnames/bind";
import { Button } from "app/components/button";
import { Tag } from "app/components/tag";
import { Icon } from "@pentatonic/components/dist/icon/icon";

const cx = classNames.bind();

export const LegislationItem = ({ element }) => {
  const { addMessageToChat } = useContext(ChatHistoryContext);
  const { showChat, setChatVisibility } = useContext(ChatViewContext);

  const askAI = () => {
    setChatVisibility(true);
    addMessageToChat({
      message: `Can you summarise ${element.heading_homepage} for me please?`,
      type: "chat",
      document_summary_id: element.id,
    });
  };

  return (
    <div
      data-testId="legislation-item"
      className={cx(
        !showChat && "md:max-w-[calc(49%-0.5rem)] lg:max-w-[calc(33%-0.5rem)]",
        `
      flex overflow-y-scroll no-scrollbar
      md:block md:overflow-y-auto w-full 
    `,
      )}
    >
      <div
        className={cx(
          "min-w-full rounded-xl p-[16px] mb-[12px] bg-white transition-shadow shadow-sm hover:shadow-md group/item",
        )}
      >
        <div className="flex justify-between">
          <h2 className="text-[#282F44] text-[18px] capitalize truncate underline-offset-4 w-[calc(100%-2rem)] group-hover/item:w-[calc(100%-13rem)]">
            <Link
              to={`/legislation/${encodeURIComponent(element.id)}`}
              className="text-ellipsis whitespace-nowrap overflow-hidden block"
            >
              {element.heading_homepage}
            </Link>
          </h2>
          <Button
            secondary
            iconOutlined
            iconGlyph="comment"
            label="Ask AI to Summarize"
            className={`
            hidden md:flex
            w-[2rem] opacity-0 whitespace-nowrap 
            group-hover/item:w-auto group-hover/item:opacity-100 
            text-[#282F44]`}
            onClick={askAI}
          />
        </div>
        <div className="flex justify-between items-center h-8 ">
          <div className="flex items-center">
            {element.jurisdiction && (
              <Flag jurisdiction={element.jurisdiction} />
            )}
            {element.topic && <Tag label={element.topic} />}
            {element.status && <Tag label={element.status} />}
          </div>
        </div>
      </div>

      <Button secondary onClick={askAI} className="ml-large mb-large md:hidden">
        <div className="flex flex-col">
          <Icon glyphName="comment" />
          <span>Ask AI</span>
        </div>
      </Button>
    </div>
  );
};
