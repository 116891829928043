export const RunTest = () => (
  <>
    <div className="pt-xxxx-large pb-x-large">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="121"
        height="176"
        viewBox="0 0 121 176"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 0C7.16344 0 0 7.16345 0 16V33C0 41.8366 7.16345 49 16 49H105C113.837 49 121 41.8366 121 33V16C121 7.16344 113.837 0 105 0H16ZM20 16C17.7909 16 16 17.7909 16 20V29C16 31.2091 17.7909 33 20 33H101C103.209 33 105 31.2091 105 29V20C105 17.7909 103.209 16 101 16H20Z"
          fill="#D9DFFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33 49H17V132.5C17 156.524 36.4756 176 60.5 176H61.5C85.5244 176 105 156.524 105 132.5V49H89V132C89 147.464 76.464 160 61 160C45.536 160 33 147.464 33 132V49Z"
          fill="url(#paint0_linear_2840_9154)"
        />
        <path
          d="M57 86C57 81.5817 60.5817 78 65 78H89V94H65C60.5817 94 57 90.4183 57 86Z"
          fill="#90AAEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65 78C60.5817 78 57 81.5817 57 86H89V78H65Z"
          fill="#B5C7F3"
        />
        <path
          d="M57 118C57 113.582 60.5817 110 65 110H89V126H65C60.5817 126 57 122.418 57 118Z"
          fill="#90AAEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65 110C60.5817 110 57 113.582 57 118H89V110H65Z"
          fill="#B5C7F3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2840_9154"
            x1="61"
            y1="49"
            x2="61"
            y2="176"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF6FF" />
            <stop offset="1" stopColor="#DAE0FC" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <h3 className="text-6xl">Test Results</h3>
    <p className="text-slate-500 my-large">
      AI test results will appear here once run.
    </p>
  </>
);
