import React from "react";
import { Select } from "app/components/select/select";
import states from "app/data/states.json";
import euCounties from "app/data/eu-countries.json";
import { useAppContext } from "app/AppContext";

export const ListFilters = ({ onSetFilter, filter, topics = [] }) => {
  const {
    documentGroup,
    groupFilterItems,
    locationFilterItems,
    statusFilterItems,
  } = useAppContext();

  return (
    <>
      <p
        className={`md:hidden text-[23px] font-['DM_Sans'] font-medium text[#282F44] pb-[16px]`}
      >
        Filters
      </p>
      {groupFilterItems?.length ? (
        <Select
          data-testId="topic-selector"
          selected={filter.topic}
          onChange={(items) => onSetFilter({ ...filter, topic: items })}
          label={documentGroup}
          items={topics.length ? topics : groupFilterItems}
          className="md:grow pb-[16px] md:pb-[8px]"
        />
      ) : null}
      {locationFilterItems?.length ? (
        <Select
          singleMode
          selected={filter.jurisdiction}
          onChange={(items) => {
            const updatedFilter = {
              ...filter,
              us_state: items.includes("EU") ? [] : filter.us_state,
              eu_country: items.includes("US") ? [] : filter.eu_country,
              jurisdiction: items,
            };

            if (!items.length) {
              updatedFilter.us_state = [];
              updatedFilter.eu_country = [];
            }

            return onSetFilter(updatedFilter);
          }}
          label="Jurisdiction"
          items={locationFilterItems}
          className="md:grow pb-[16px] md:pb-0"
        />
      ) : null}
      {filter?.jurisdiction?.includes("US") && (
        <Select
          selected={filter.us_state}
          onChange={(items) => onSetFilter({ ...filter, us_state: items })}
          label="State"
          items={states}
          className="md:grow pb-[16px] md:pb-0"
        />
      )}
      {filter?.jurisdiction?.includes("EU") && (
        <Select
          selected={filter.eu_country}
          onChange={(items) => onSetFilter({ ...filter, eu_country: items })}
          label="Country"
          items={euCounties}
          className="md:grow pb-[16px] md:pb-0"
        />
      )}
      {statusFilterItems?.length ? (
        <Select
          selected={filter.status}
          onChange={(items) => onSetFilter({ ...filter, status: items })}
          label="Status"
          items={statusFilterItems}
          className="grow"
        />
      ) : null}
    </>
  );
};
