import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { MainNav, Input, Button, TextArea, Icon } from "@pentatonic/components";
import { Select } from "app/components";
import { useMutation, useQuery } from "react-query";
import {
  deleteLegislationQuery,
  fetchDocumentById,
  saveLegislationQuery,
} from "app/data/fetch-documents";
import { useAppContext } from "app/AppContext";
import states from "app/data/states.json";
import euCounties from "app/data/eu-countries.json";
import { ToastContainer, useToast } from "app/components/toast";
import { AdminTests } from "app/components/admin-tests";

function extractFilenameFromURL(urlString) {
  if (!urlString) return null;

  try {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);
    const contentDisposition = params.get("response-content-disposition");
    if (!contentDisposition) {
      return null;
    }

    const decodedContentDisposition = decodeURIComponent(contentDisposition);
    const filenameMatch = decodedContentDisposition.split("filename = ");

    return filenameMatch.find((name) => name.includes("pdf"));
  } catch (error) {
    console.error("Invalid URL provided:", error);
    return null;
  }
}

export const AdminLegislation = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [legislation, setLegislation] = useState({});
  const [file, setFile] = useState({});
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  const { addToast, removeToast, toasts } = useToast();

  const {
    documentGroup,
    groupFilterItems,
    locationFilterItems,
    statusFilterItems,
    documentTypeSingular,
  } = useAppContext();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }

    getIdTokenClaims().then((user) => {
      if (user?._permissions) {
        setUserPermissions(user._permissions.split(","));
      }
    });
  }, [isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims]);

  const { id } = useParams();
  const { id: contextId } = useAppContext();
  const navigate = useNavigate();

  const {
    data: { data } = {},
    refetch: refetchLegislation,
    isLoading: isLoadingLegislation,
  } = useQuery({
    ...fetchDocumentById({ id, context: contextId }),
    enabled: id !== "new",
  });

  useEffect(() => {
    if (legislation?.id !== id && !isLoadingLegislation && id !== "new") {
      setLegislation(data);
    }
  }, [legislation, data, id, isLoadingLegislation]);

  const { mutate: saveLegislation } = useMutation({
    mutationFn: saveLegislationQuery({
      context: contextId,
      isNew: id === `new`,
    }),
    mutationKey: ["saveLegislationQuery", id],
    onSuccess: (data) => {
      addToast("Saved legislation");
      navigate(`/admin/${data.id}`);
      refetchLegislation();
    },
  });

  const { mutate: deleteLegislation } = useMutation({
    mutationFn: deleteLegislationQuery({
      id,
      context: contextId,
    }),
    mutationKey: ["deleteLegislationQuery", id],
    onSuccess: () => navigate("/admin"),
  });

  if (!userPermissions.includes("legisaltion-tracker.admin")) return null;

  return (
    <>
      <ToastContainer toasts={toasts} removeToast={removeToast} />
      <div className="bg-white h-full overflow-scroll">
        <MainNav
          handleSignIn={loginWithRedirect}
          handleSignOut={() =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            })
          }
          user={user && { ...user, avatar: user.picture }}
        />
        <div
          className={`
          flex p-x-large 
          border-b border-b-[#DAE3F9]
       `}
        >
          <div className="grow">
            <span className="flex items-center text-ellipsis whitespace-nowrap overflow-hidden block">
              <Link to={"/admin"}>
                <span className="inline md:hidden">
                  <Icon
                    glyphName="chevron_left"
                    className="relative top-[6px] !inline text-[#595F77]"
                  />
                  Back to{" "}
                </span>
                {`${documentTypeSingular.charAt(0).toUpperCase()}${documentTypeSingular.slice(1)}`}
              </Link>
              <span className="hidden md:inline text-[#595F77]">
                {" "}
                <Icon
                  glyphName="chevron_right"
                  className="relative top-[2px] !inline text-[#595F77]"
                />
              </span>

              <span className="hidden md:inline text-[#595F77]">
                {legislation.heading_homepage?.length > 50
                  ? legislation.heading_homepage.slice(0, 50)
                  : legislation.heading_homepage}
                ...
              </span>
            </span>
            <h1
              className={`
              text-6xl font-medium text-[#282F44] text-[29px] 
            `}
            >
              {legislation.heading_homepage}
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="flex space-x-regular">
              {legislation.internal_status === "archived" ? (
                <Button
                  secondary
                  minimal
                  iconGlyph="delete"
                  onClick={() => {
                    deleteLegislation();
                    navigate("/admin/archived");
                  }}
                />
              ) : (
                <Button
                  secondary
                  minimal
                  iconGlyph="archive"
                  onClick={() => {
                    saveLegislation({
                      payload: {
                        ...legislation,
                        internal_status: "archived",
                      },
                    });

                    if (legislation.internal_status === "draft") {
                      navigate("/admin/draft");
                    }
                  }}
                />
              )}
              <Button
                secondary
                label="Save"
                onClick={() =>
                  saveLegislation({
                    payload: {
                      id: uuidv4(),
                      internal_status: "draft",
                      document_type: "Raw",
                      file_extension: "pdf",
                      ...legislation,
                    },
                    fileData: file,
                  })
                }
              />
              <Button
                label="Publish"
                onClick={() =>
                  saveLegislation({
                    payload: {
                      ...legislation,
                      internal_status: "published",
                    },
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-x-large space-y-x-large border-r border-slate-200">
            <Input
              id={`heading`}
              label={`Heading`}
              value={legislation.heading_homepage}
              onChange={(e) => {
                setLegislation({
                  ...legislation,
                  heading_homepage: e.target.value,
                });
              }}
            />
            <Input
              id={`full_name`}
              label={`Full Legislation Name`}
              value={legislation.legislation_id}
              onChange={(e) => {
                setLegislation({
                  internal_id: e.target.value.replaceAll(" ", "_"),
                  ...legislation,
                  legislation_id: e.target.value,
                });
              }}
            />

            <Input
              id={`url`}
              label={`Source URL`}
              value={legislation.url}
              onChange={(e) => {
                setLegislation({
                  ...legislation,
                  url: e.target.value,
                });
              }}
            />

            <div>
              <label
                className={`text-small text-slate-800`}
                htmlFor="legislationFile"
              >
                Source Document
              </label>
              <div className="border relative border-slate-300 bg-slate-50 w-full px-large py-regular rounded-lg outline-none focus:border-blue-600 mt-regular">
                <p className="m-small left-0 w-full h-full text-center">
                  {file?.name ||
                    extractFilenameFromURL(legislation?.s3_download_url) ||
                    "Upload document pdf"}
                </p>
                <input
                  type="file"
                  id="legislationFile"
                  name="legislationFile"
                  className={`opacity-0 w-full absolute top-0 left-0 w-full h-full cursor-pointer`}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>

              <Button
                minimal
                secondary
                iconGlyph={`download`}
                label={`Download`}
                onClick={() => {
                  window.open(legislation.s3_download_url, "_blank");
                }}
              />
            </div>

            <TextArea
              id={`summary`}
              rows="8"
              label={`Description`}
              value={legislation.description}
              onChange={(e) => {
                setLegislation({
                  ...legislation,
                  description: e.target.value,
                });
              }}
            />

            <Select
              singleMode
              data-testId="topic-selector"
              selected={[legislation.topic]}
              onChange={([selected]) =>
                setLegislation({ ...legislation, topic: selected })
              }
              label={documentGroup}
              items={groupFilterItems}
              className="md:grow pb-[16px] md:pb-[8px]"
            />

            <Input
              id={`language`}
              label={`Language`}
              value={legislation.language}
              onChange={(e) => {
                setLegislation({
                  ...legislation,
                  language: e.target.value,
                });
              }}
            />

            <Select
              singleMode
              selected={[legislation.jurisdiction]}
              onChange={([selected]) =>
                setLegislation({ ...legislation, jurisdiction: selected })
              }
              label="Jurisdiction"
              items={locationFilterItems}
              className="md:grow pb-[16px] md:pb-0"
            />

            {legislation?.jurisdiction?.includes("US") && (
              <Select
                singleMode
                selected={[legislation.us_state]}
                onChange={([selected]) =>
                  setLegislation({ ...legislation, us_state: selected })
                }
                label="State"
                items={states}
                className="md:grow pb-[16px] md:pb-0"
              />
            )}
            {legislation?.jurisdiction?.includes("EU") && (
              <Select
                singleMode
                selected={[legislation.eu_country]}
                onChange={([selected]) =>
                  setLegislation({ ...legislation, eu_country: selected })
                }
                label="Country"
                items={euCounties}
                className="md:grow pb-[16px] md:pb-0"
              />
            )}

            <Select
              singleMode
              selected={[legislation.status]}
              onChange={([selected]) =>
                setLegislation({ ...legislation, status: selected })
              }
              label="Status"
              items={statusFilterItems}
              className="grow"
            />
          </div>

          <AdminTests />
        </div>
      </div>
    </>
  );
};
