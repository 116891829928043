import { useAuth0 } from "@auth0/auth0-react";
import { MainNav } from "@pentatonic/components";
import { useAppContext } from "app/AppContext";

const envs = {
  localhost: `dev`,
  "legislation-tracker-client-dev.philip-134.workers.dev": `dev`,
  "legislation-dev.pentatonic.com": `dev`,
  "legislation-demo.pentatonic.com": `demo`,
};

export const Header = ({ hideSignIn }) => {
  const { loginWithRedirect, logout, user, error } = useAuth0();
  const { name } = useAppContext();

  if (error) {
    console.log(error);
  }

  return (
    <MainNav
      hideSignIn={hideSignIn}
      appName={name}
      handleSignIn={() =>
        loginWithRedirect({
          appState: {
            returnTo: window.location.href,
          },
        })
      }
      handleSignOut={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
      user={user && { ...user, avatar: user.picture }}
      env={envs[window.location.hostname] || `production`}
    />
  );
};
