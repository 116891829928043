import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Icon } from "@pentatonic/components";
import { useAllLegislationDocuments } from "app/hooks/use-all-legislation-documents";
import format from "date-fns/format";

export const AdminHome = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("draft");
  const { isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims } =
    useAuth0();

  const location = useLocation();

  useEffect(() => {
    setSelectedTab(location.pathname.replace("/admin/", ""));
  }, [location, setSelectedTab]);

  const { filteredData: legislationDocuments } = useAllLegislationDocuments({
    status: selectedTab,
    staleTime: 0,
  });

  const filteredLegislation =
    legislationDocuments?.filter(
      ({ internal_status }) =>
        selectedTab === internal_status ||
        (selectedTab === "draft" && !internal_status),
    ) ?? [];

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }

    getIdTokenClaims().then((user) => {
      if (user?._permissions) {
        setUserPermissions(user._permissions.split(","));
      }
    });
  }, [isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims]);

  const navigate = useNavigate();

  if (!userPermissions.includes("legisaltion-tracker.admin")) return null;

  return (
    <div className="bg-white">
      <Table
        onRowClick={({ row }) => navigate(`/admin/${row.id}`)}
        columns={[
          { Header: "Title", accessor: "heading_homepage", isSortable: true },
          {
            Header: "Added",
            accessor: "created",
            Cell: ({ row }) => format(new Date(row.created), "dd/mm/yyyy"),
            isSortable: true,
          },
          {
            Header: "Tested",
            accessor: "last_tested",
            Cell: ({ row }) =>
              row.last_tested ? (
                <Icon glyphName="check_circle" className={`text-green-300`} />
              ) : (
                <div className="w-12 h-1" />
              ),
            isSortable: true,
            maxContentWidth: true,
          },
        ]}
        data={filteredLegislation.map((row) => ({
          ...row,
        }))}
      />
    </div>
  );
};
