import { useState, useEffect, useContext, useRef } from "react";
import { PaginationNavPresentation } from "app/components/pagination/pagination";
import { LegislationItem } from "app/components/legislation-item/legislation-item";
import { ListFilters } from "./list-filters";
import { ChatViewContext, FilterViewContext } from "app";
import { Button } from "app/components/button";
import { Icon } from "../icon";
import { MoonLoader } from "react-spinners";
import { useAppContext } from "app/AppContext";
import { useAllLegislationDocuments } from "app/hooks/use-all-legislation-documents";

const findMatchingItems = ({ data, filter }) => {
  if (!Object.values(filter).flat().length) return data;

  return Object.entries(filter).reduce((matchedResults, [key, values = []]) => {
    if (!values.length) return matchedResults;

    return matchedResults.filter((item) => {
      return values
        .map((value) =>
          value?.value ? value?.value.toLowerCase() : value?.toLowerCase(),
        )
        .includes(item[key]?.toLowerCase());
    });
  }, data);
};

export const LegislationList = () => {
  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsInView, setItemsInView] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const persistedFilter = localStorage.getItem("filters");
  const initialFilter = persistedFilter ? JSON.parse(persistedFilter) : {};
  const [filter, setFilter] = useState(initialFilter);
  const { documentTypeSingular, documentTypePlaural } = useAppContext();

  const { showChat, setChatVisibility } = useContext(ChatViewContext);
  const [showFilters, setShowFilters] = useContext(FilterViewContext);

  const {
    filteredData: legislationDocuments,
    isLoading,
    totalLegislationCount,
  } = useAllLegislationDocuments({ searchValue });

  useEffect(() => {
    // Save the filter state to localStorage whenever it changes
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    if (
      legislationDocuments?.length &&
      !itemsInView.length &&
      !Object.values(filter).flat().length
    ) {
      setItemsInView(legislationDocuments.slice(0, 10));
    }
  }, [setItemsInView, legislationDocuments, itemsInView, filter]);

  const onToggleChatView = (showChat) => {
    setChatVisibility(showChat);
  };

  const filteredData = findMatchingItems({
    data: legislationDocuments || [],
    filter,
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const nextPage = filteredData.slice(startIndex, endIndex);
    if (JSON.stringify(itemsInView) !== JSON.stringify(nextPage)) {
      setItemsInView(filteredData.slice(startIndex, endIndex));
    }
  }, [filteredData, startIndex, endIndex, itemsInView]);

  const topRef = useRef(null);

  const scrollTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="pt-[16px] pb-[24px] md:pt-[4px] md:pb-[24px]">
        <div className="flex space-x-small">
          <div className="grow relative mb-[24px]">
            <input
              placeholder={`Search ${documentTypeSingular}`}
              type="search"
              className="w-full rounded-[8px] border border-[#C6CCE8] px-[12px] py-[10px]"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {!searchValue && (
              <Icon
                className={`!absolute right-[12px] !top-[14px]`}
                glyphName={`search`}
              />
            )}
          </div>
          <div>
            <Button
              secondary
              badge={Object.values(filter).flat().filter(Boolean).length}
              label="Filter"
              iconGlyph="filter_list"
              onClick={() => setShowFilters(!showFilters)}
            />
          </div>
          <div>
            <Button
              secondary
              className="hidden md:flex"
              label={`${showChat ? "Hide" : "Show"} AI Chat`}
              iconGlyph="list_alt"
              onClick={() => onToggleChatView(!showChat)}
            />
          </div>
        </div>
        <div className="flex flex-col align-center">
          <span className="text-[#464C64]">
            Currently tracking {totalLegislationCount} {documentTypePlaural}
          </span>
          <p className="text-[13px] text-[#8086A0]">
            Displaying {startIndex + 1} -{" "}
            {itemsInView.length === itemsPerPage
              ? (currentPage + 1) * itemsPerPage
              : currentPage * itemsPerPage + itemsInView.length}{" "}
            of {filteredData.length}
          </p>
        </div>
      </div>
      {showFilters && (
        <div
          className={`
          z-50
          h-full md:h-auto
          flex flex-col md:flex-row justify-start
          fixed md:relative 
          bottom-0 left-0 right-0 
          bg-white md:bg-transparent
          p-[16px] pr-[8px] md:px-0
          `}
        >
          <button
            className={`fixed top-0 right-0 md:hidden`}
            onClick={() => {
              setFilter({});
              setShowFilters(!showFilters);
            }}
          >
            <Icon
              outlined
              className={`!text-[24px] px-[24px] py-[16px]`}
              glyphName="close"
            />
          </button>

          <ListFilters
            filter={filter}
            data={itemsInView}
            topics={Array.from(
              new Set(legislationDocuments?.map(({ topic }) => topic)),
            ).sort()}
            onSetFilter={(filterToSet) => setFilter(filterToSet)}
          />
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="text-white px-[16px] py-[12px] rounded-lg text-[16px] bg-[#173991] md:hidden"
          >
            Apply Filters
          </button>
        </div>
      )}
      {filteredData.length ? (
        <div className="no-scrollbar">
          <div className="relative" ref={topRef}>
            <div className="flex flex-wrap justify-between">
              {itemsInView.map((element, index) => (
                <LegislationItem element={element} key={index} />
              ))}
            </div>
            <PaginationNavPresentation
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              scrollTop={scrollTop}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center pt-[30%]">
          {isLoading ? (
            <>
              <p>Loading...</p>
              <MoonLoader size={30} />
            </>
          ) : (
            <>
              <p className="text-[#282F44] text-[18px] font-medium">
                No results found
              </p>
              <p className="text-[#464C64] mt-4px">
                Try adjusting or resetting the filters
              </p>
              <Button
                secondary
                onClick={() => setFilter({})}
                label="Reset Filters"
                className="!ml-0 !mt-[16px] py-[8px] px-[16px]"
              />
            </>
          )}
        </div>
      )}
    </>
  );
};
