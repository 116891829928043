import { useContext, useRef, useState } from "react";
import { findScrollableParent } from "app/helpers/dom-helpers";
import useInterval from "use-interval";
import { useMediaQuery } from "react-responsive";
import { ChatViewContext } from "app";

export const useAutoScroll = () => {
  const chatContainerRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const isMediumScreen = useMediaQuery({ query: `(min-width: 768px)` });
  const { showChat } = useContext(ChatViewContext);

  useInterval(
    () => {
      if (chatContainerRef.current && !hasScrolled && isMediumScreen) {
        const scroll =
          chatContainerRef.current.scrollHeight -
          chatContainerRef.current.clientHeight;
        chatContainerRef.current.scrollTo({
          top: scroll,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    isMediumScreen ? 1000 : Infinity,
  );

  useInterval(
    () => {
      if (!isMediumScreen && showChat && !hasScrolled) {
        findScrollableParent(chatContainerRef.current)?.scrollTo({
          // with the way the header now works on mobile this is broken again
          left: 0,
          top: chatContainerRef.current.clientHeight,
          behavior: "smooth",
        });
      }
    },
    isMediumScreen ? Infinity : 1000,
  );

  return { chatContainerRef, handleScroll: setHasScrolled };
};
