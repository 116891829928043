import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MainNav, Button } from "@pentatonic/components";
import { useLocation, useNavigate } from "react-router-dom";

export const AdminLayout = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState([]);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  const location = useLocation();
  const selectedTab = location.pathname.replace("/admin/", "");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }

    getIdTokenClaims().then((user) => {
      if (user?._permissions) {
        setUserPermissions(user._permissions.split(","));
      }
    });
  }, [isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims]);

  if (!userPermissions.includes("legisaltion-tracker.admin")) return null;

  return (
    <div className="bg-white h-full">
      <MainNav
        handleSignIn={loginWithRedirect}
        handleSignOut={() =>
          logout({
            logoutParams: { returnTo: window.location.origin },
          })
        }
        user={user && { ...user, avatar: user.picture }}
      />
      <div
        className={`
          flex p-x-large 
          border-b border-b-[#DAE3F9]
       `}
      >
        <div className="grow">
          <h1
            className={`
              text-6xl font-medium text-[#282F44] text-[29px] 
            `}
          >
            Legislation Tracker
          </h1>
          <div
            className={`
          flex space-x-regular pt-large
          overflow-x-scroll no-scrollbar
        `}
          >
            <Button
              minimal={!(selectedTab === "draft")}
              onClick={() => {
                navigate("/admin/draft");
              }}
              secondary
              label={`Drafts`}
            />
            <Button
              minimal={!(selectedTab === "published")}
              onClick={() => {
                navigate("/admin/published");
              }}
              secondary
              label={`Published`}
            />
            <Button
              minimal={!(selectedTab === "archived")}
              secondary
              onClick={() => {
                navigate("/admin/archived");
              }}
              label={`Archived`}
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="flex space-x-regular">
            <Button secondary label="Run Tests" iconGlyph="labs" />
            <Button
              label="Add Legislation"
              iconGlyph="add_circle"
              onClick={() => navigate(`/admin/new`)}
            />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
