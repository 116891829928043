import { useEffect, useState } from "react";

const thinkingMessages = [
  { message: "Thinking...", timeout: 10000 },
  { message: "Drafting...", timeout: 10000 },
  { message: "Finalising...", timeout: 10000 },
  { message: "Taking longer than usual", timeout: Infinity },
];

export const useThinkingMessages = ({ isLoading }) => {
  const [{ message, timeout }, setMessage] = useState(thinkingMessages[0]);

  useEffect(() => {
    let timer;
    if (!isLoading) setMessage(thinkingMessages[0]);
    if (isLoading) {
      const currentIndex = thinkingMessages.findIndex(
        (think) => think.message === message,
      );

      const nextTimeoutMessage = thinkingMessages[currentIndex + 1];

      if (nextTimeoutMessage) {
        timer = setTimeout(() => {
          setMessage(nextTimeoutMessage);
        }, timeout);
      }
    }
    return () => clearTimeout(timer);
  }, [message, setMessage, timeout, isLoading]);

  return message;
};
