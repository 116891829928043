import PentatonicLogoBlack from "app/assets/Pentatonic-logo.svg";
import NotFound404 from "app/assets/404-pentatonic.png";
import { Button } from "app/components/button";
import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <div
      className={`h-[100vh] min-h-[100vh] bg-gradient-to-r from-[#DAF2F9] via-[#F0DAF9] to-[#D5F8F1] flex justify-center`}
    >
      <div className={`w-[376px] flex flex-col justify-center h-full`}>
        <img
          src={PentatonicLogoBlack}
          className="h-[16px] md:h-[20px]"
          alt="pentatonic logo"
        />
        <img
          src={NotFound404}
          alt="404 not found"
          className={`mix-blend-darken mt-[24px]`}
        />
        <h2
          className={`text-[26px] text-[#282F44] font-['DM_Sans'] font-medium text-center`}
        >
          Page Not Found
        </h2>
        <p
          className={`text-[#8086A0] text-[16px] text-center mt-[16px] mb-[24px]`}
        >
          Sorry, this page doesn’t appear to exist. Perhaps it’s been turned
          into something else?
        </p>
        <div className="flex justify-center">
          <Link to="/">
            <Button secondary label="Return Home" />
          </Link>
        </div>
      </div>
    </div>
  );
};
