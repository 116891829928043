import { useCallback, useEffect, useState } from "react";
import { BOT_USER_NAME, USERNAME } from "app";
import { useAuth0 } from "@auth0/auth0-react";

export const TIMEOUT_MESSAGE = `Sorry, I was unable to answer your question this time around. Please ask again`;

export const useTimeoutMessage = (
  timeout,
  {
    onCancelMessage,
    loadingResponse,
    sendChatMessage,
    chatHistory,
    setChatInputValue,
  },
) => {
  const { user } = useAuth0();
  const [chatTimedout, setChatTimedout] = useState(false);
  const [timeoutStarted, setTimeoutStarted] = useState(false);

  const cancelMessage = useCallback(() => {
    onCancelMessage();
    setChatTimedout(true);

    sendChatMessage({
      message: TIMEOUT_MESSAGE,
      author: BOT_USER_NAME,
      type: "chat",
      isResponse: true,
    });

    const lastUserMessage = chatHistory
      .filter(({ author }) => author === USERNAME || author === user?.email)
      .at(-1);

    lastUserMessage?.message && setChatInputValue(lastUserMessage.message);
  }, [
    onCancelMessage,
    setChatTimedout,
    chatHistory,
    user?.email,
    sendChatMessage,
    setChatInputValue,
  ]);

  useEffect(() => {
    let messageTimeout;
    if (loadingResponse && !timeoutStarted) {
      setTimeoutStarted(true);
      messageTimeout = setTimeout(() => cancelMessage(), timeout);
    }

    if (!loadingResponse) {
      setTimeoutStarted(false);
    }

    return () => clearTimeout(messageTimeout);
  }, [
    cancelMessage,
    loadingResponse,
    timeout,
    setTimeoutStarted,
    timeoutStarted,
  ]);

  return {
    chatTimedout,
  };
};
