import { useState, useEffect, useRef } from "react";
import { Header, Hero, ChatBot } from "app/components";

import classNames from "classnames/bind";
import { Icon } from "app/components/icon";
import { useMediaQuery } from "react-responsive";
import { ChatViewContext, ChatHistoryContext, FilterViewContext } from "app";
import { Link } from "react-router-dom";
import { useChatState } from "app/hooks/use-chat-state";
import { useAppContext } from "app/AppContext";

const cx = classNames.bind();

export const Layout = ({ children }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const headerRef = useRef(null);
  const isMediumScreen = useMediaQuery({ query: `(min-width: 768px)` });
  const { backgroundStyle } = useAppContext();

  const {
    goToChat,
    deleteChat,
    setChatVisibility,
    addMessageToChat,
    chatHistory,
  } = useChatState({ setShowChat });

  useEffect(() => {
    if (isMediumScreen) {
      setShowChat(true);
    } else {
      setShowChat(false);
    }
  }, [isMediumScreen]);

  return (
    <ChatHistoryContext.Provider
      value={{ chatHistory, addMessageToChat, goToChat, deleteChat }}
    >
      <ChatViewContext.Provider value={{ showChat, setChatVisibility }}>
        <FilterViewContext.Provider value={[showFilters, setShowFilters]}>
          <div className={backgroundStyle}>
            <div className="w-full h-[calc(100dvh-90px)] md:h-full mt-[90px] md:mt-0 overflow-y-scroll md:overflow-y-auto no-scrollbar">
              <div
                className={cx(
                  "md:py-[1.7rem] md:px-[2.8rem] md:pl-[34px] flex flex-col",
                  !showChat && "p-[12px]",
                )}
              >
                <header ref={headerRef}>
                  <div className="absolute top-0 left-0 z-20 w-screen md:static md:mt-[-24px] md:mx-[-34px]">
                    <Header hideSignIn={showChat && window.innerWidth < 768} />
                  </div>

                  <Hero />
                </header>
                <div
                  style={{
                    height: isMediumScreen
                      ? `calc(100vh - ${
                          headerRef?.current?.clientHeight || 100
                        }px - 4rem - 5px)`
                      : "auto",
                  }}
                  className={cx(
                    `
                      h-[calc(100dvh-90px)] ${
                        showChat && "min-h-[calc(100dvh-90px)]"
                      }
                      mb-[24px] md:mb-0 md:min-h-0
                      grid grid-cols-1  ${
                        !showChat ? "md:grid-cols-1" : "md:grid-cols-2"
                      }
                    `,
                  )}
                >
                  <div
                    className={cx(
                      "overflow-y-scroll overflow-x-hidden no-scrollbar px-[6px]",
                      showChat && "hidden md:block",
                    )}
                  >
                    {children}
                  </div>

                  <div
                    className={cx(
                      !showChat && "w-0 overflow-hidden h-0",
                      showChat &&
                        "bottom-0 ml-0 md:overflow-hidden md:ml-[24px]",
                    )}
                  >
                    <ChatBot />
                  </div>

                  <button
                    onClick={() => setShowChat(!showChat)}
                    className={cx(
                      "flex md:hidden rounded-full w-[56px] h-[56px] bg-[#173991] fixed items-center justify-center",
                      showChat &&
                        " h-[24px] bg-[transparent] outline-0 top-0 rigth-0 bottom-0 right-[4px] z-50 px-[24px] py-[8px]",
                      !showChat &&
                        "shadow-[0_8px_8px_-4px_rgba(0,0,0,0.08),_0px_0px_8px_2px_rgba(0,0,0,0.08)] bottom-6 right-6 z-30",
                    )}
                  >
                    <Icon
                      outlined
                      className={cx(
                        "leading-none",
                        showChat && "!text-[24px]",
                        !showChat && "!text-[30px] text-white",
                      )}
                      glyphName={showChat ? "close" : "chat"}
                    />
                  </button>
                </div>
              </div>

              {showChat && !isMediumScreen ? null : (
                <div
                  className={`
              flex flex-col md:flex-row 
              justify-center md:justify-between
              text-center md:text-left
              text-[10px] text-[#8086A0] 
              bottom-[12px] relative mx-[40px] pb-[50px] md:pb-0`}
                >
                  <span className="">
                    © 2023, Pentatonic GMBH |{" "}
                    <a href="mailto:legislation@pentatonic.com">
                      legislation@pentatonic.com
                    </a>{" "}
                    | Geschäftsführer Johann Bödecker
                  </span>

                  <span className="">
                    <Link to="/disclaimer">Disclaimer</Link> |{" "}
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </span>
                </div>
              )}
            </div>
          </div>
        </FilterViewContext.Provider>
      </ChatViewContext.Provider>
    </ChatHistoryContext.Provider>
  );
};
