import React, { useState, useContext } from "react";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon";
import {
  BOT_USER_NAME,
  ChatHistoryContext,
  ChatViewContext,
  USERNAME,
} from "app";
import classNames from "classnames/bind";
import md5 from "md5";
import { useAuth0 } from "@auth0/auth0-react";
const cx = classNames.bind();

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition || null;

export const ChatInput = ({
  chatDisabled,
  sendChatMessage,
  chatInputValue,
  setChatInputValue,
  isLoading,
  cancel,
}) => {
  const { showChat } = useContext(ChatViewContext);
  const { goToChat, chatHistory, deleteChat } = useContext(ChatHistoryContext);
  const [showChats, setShowChats] = useState(false);
  const { user } = useAuth0();
  const [rerenderCount, setRerenderCount] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const recognition = SpeechRecognition ? new SpeechRecognition() : null;

  // If speech recognition is supported, set up event handlers
  if (recognition) {
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setChatInputValue(transcript);
      setIsListening(false); // Stop listening once we get a result
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  }

  const toggleListen = () => {
    if (!recognition) return;
    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      recognition.start();
      setIsListening(true);
    }
  };

  const activeChat = `chat-history-${md5(JSON.stringify(chatHistory))}`;
  const storedChats = Object.entries({ ...localStorage })
    .filter(([key]) => key.startsWith(`chat-history-`))
    .sort(([, a], [, b]) => {
      // find the latest time stamp in a, and compare to the latest in b
      const aChat = JSON.parse(a);
      const bChat = JSON.parse(b);
      return (
        bChat.reduce(
          (latestTimestamp, { timestamp }) =>
            timestamp > latestTimestamp ? timestamp : latestTimestamp,
          0,
        ) -
        aChat.reduce(
          (latestTimestamp, { timestamp }) =>
            timestamp > latestTimestamp ? timestamp : latestTimestamp,
          0,
        )
      );
    });

  const sendMessage = () => {
    sendChatMessage({
      message: chatInputValue,
      type: "chat",
    });
  };

  const findLabelForChat = (chatContent) => {
    const firstSignedOutMessage = chatContent.find(
      ({ author }) => author === USERNAME,
    )?.message;
    const firstSignedInMessage = chatContent.find(
      ({ author }) => author && author === user?.email,
    )?.message;
    const firstBotMessage = chatContent.find(
      ({ author }) => author === BOT_USER_NAME,
    )?.message;

    return firstSignedInMessage || firstSignedOutMessage || firstBotMessage;
  };

  return (
    <div
      className={`
        bg-[rgba(255,255,255,0.2)]
        md:pb-[16px] md:px-[16px]
        md:rounded-b-[16px]
      `}
    >
      <div
        key={rerenderCount}
        className={cx(
          showChat && "fixed bottom-0",
          "w-full md:relative border-t-[#DAE3F9] md:rounded-[16px] border-t px-[8px] py-[8px] bg-white flex",
          chatDisabled && "cursor-no-drop",
          showChats && "md:rounded-t-[0px]",
          "md:shadow-[0px_2px_2px_-1px_rgba(0,0,0,0.16),_0px_0px_2px_1px_rgba(0,0,0,0.08)]",
        )}
      >
        {showChats && (
          <div
            className={`
              absolute w-full 
              md:rounded-t-2xl 
              right-0 bottom-[100%] 
              bg-white p-[12px]
              md:shadow-[0px_-2px_2px_-1px_rgba(0,0,0,0.16),_0px_-2px_2px_1px_rgba(0,0,0,0.08)]
          `}
          >
            <p
              className={`text-[18px] p-[8px] flex justify-between items-center`}
            >
              <span className={`font-medium`}>Chats</span>
              <Button
                iconGlyph="close"
                iconClassName={`!m-0`}
                minimal
                secondary
                onClick={() => setShowChats(false)}
              />
            </p>
            {storedChats.map(([chatLabel, chatContent]) => {
              return (
                <div
                  key={chatLabel}
                  className={`
                flex
                group/chat-item
              `}
                >
                  <Button
                    minimal
                    secondary
                    className={`
                      w-[calc(100%-56px)] px-[8px] py-[4px] !m-0 justify-between`}
                    onClick={() => {
                      cancel();
                      goToChat({ chatId: chatLabel });
                      setShowChats(false);
                    }}
                  >
                    <p
                      className={`
                        text-left text-ellipsis 
                        overflow-hidden whitespace-nowrap 
                        text-[#282F44]
                        w-[calc(100%-24px)]
                      `}
                    >
                      {findLabelForChat(JSON.parse(chatContent))}
                    </p>
                    {activeChat === chatLabel && <Icon glyphName="done" />}
                  </Button>
                  <Button
                    minimal
                    secondary
                    iconGlyph="close"
                    className={`
                    md:opacity-0 group-hover/chat-item:opacity-100
                 `}
                    onClick={() => {
                      deleteChat({ chatId: chatLabel });
                      setRerenderCount(rerenderCount + 1);
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div
          className={`
            w-full 
            border border-[#C6CCE8] 
            focus-within:border-[1.5px] focus-within:border-[#173991]
            rounded-[8px] flex px-[12px] py-[3px]
          `}
        >
          <input
            type="text"
            placeholder="Ask a question about legislation..."
            className={cx(
              "text-[13px] w-full bg-white focus:outline-none placeholder:text-[#C6CCE8]",
              chatDisabled && "cursor-no-drop",
            )}
            value={chatInputValue}
            onChange={({ target }) => setChatInputValue(target.value)}
            onKeyDown={({ key }) => {
              if (
                key === "Enter" &&
                !(isLoading || chatDisabled) &&
                chatInputValue
              ) {
                sendMessage();
              }
            }}
          />

          {recognition && (
            <Button
              minimal
              secondary
              onClick={toggleListen}
              iconGlyph={isListening ? "stop" : "mic"}
            />
          )}

          {isLoading ? (
            <Button
              minimal
              secondary
              dataTestId="cancel-message"
              iconGlyph="clear"
              label="Cancel"
              onClick={() => cancel()}
            />
          ) : (
            <Button
              dataTestId="send-message"
              minimal={!chatInputValue}
              secondary={!chatInputValue}
              disabled={chatDisabled}
              iconGlyph="send"
              onClick={() => sendMessage()}
            />
          )}
        </div>

        <div className={`flex justify-end relative`}>
          <Button
            dataTestId="new-chat"
            disabled={isLoading}
            minimal
            secondary
            iconGlyph="add"
            className={`group/add-button overflow-hidden md:transition-all`}
            iconClassName={`!m-0`}
            onClick={() => {
              goToChat({ chatId: `new` });
            }}
          >
            {chatInputValue.length ? (
              <span
                className={`
                opacity-0 w-0
                md:opacity-0 md:w-0 md:transition-all
              `}
              >
                New Chat
              </span>
            ) : (
              <span className={`w-0 lg:w-[6rem] opacity-0 lg:opacity-100`}>
                New Chat
              </span>
            )}
          </Button>
          <Button
            minimal
            secondary
            iconGlyph="menu"
            className={`group/view-button overflow-hidden`}
            iconClassName={`!m-0`}
            onClick={() => setShowChats(!showChats)}
          >
            <span
              className={`
                opacity-0 w-0
                pt-[3px] md:opacity-0 md:w-0 md:transition-all
              `}
            >
              View Chats
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
