import { createContext, useContext, useState } from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { Button } from "@pentatonic/components";

const ToastContext = createContext(null);

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (content) => {
    const id = Math.random().toString(36).substring(2, 9);
    setToasts((prevToasts) => [...prevToasts, { id, content }]);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      {children}
    </ToastContext.Provider>
  );
};

export const ToastContainer = ({ toasts, removeToast }) => {
  return (
    <ToastPrimitive.Provider swipeDirection="right">
      {toasts.map((toast) => (
        <ToastPrimitive.Root
          key={toast.id}
          onOpenChange={() => removeToast(toast.id)}
          duration={5000}
          className="absolute bottom-0 right-0 m-2"
        >
          <div className="border rounded pl-large py-x-small flex items-center">
            <div>{toast.content}</div>
            <Button
              secondary
              minimal
              iconGlyph="close"
              onClick={() => removeToast(toast.id)}
            />
          </div>
        </ToastPrimitive.Root>
      ))}

      <ToastPrimitive.Viewport />
    </ToastPrimitive.Provider>
  );
};
