import React, { useEffect, useState } from "react";

export const TextDisplay = ({ text, className }) => {
  const maxLength = 600;
  const [isFullTextShown, setIsFullTextShown] = useState(false);

  useEffect(() => {
    if (text?.length < maxLength) {
      setIsFullTextShown(true);
    }
  }, [setIsFullTextShown, text]);

  const toggleTextDisplay = () => {
    setIsFullTextShown(!isFullTextShown);
  };

  return (
    <div className={className}>
      <span className="text-gray-800 text-sm">
        {isFullTextShown ? text : `${text?.substring(0, maxLength)}...`}
      </span>
      {text?.length > maxLength && (
        <button
          onClick={toggleTextDisplay}
          className="text-blue-500 text-sm hover:text-blue-800 transition duration-300 ease-in-out inline"
        >
          {isFullTextShown ? "Show less" : "Show more"}
        </button>
      )}
    </div>
  );
};
