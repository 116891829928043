import React, { useContext } from "react";
import { useIsNestedPage } from "app/hooks/use-is-nested-page";
import { useMediaQuery } from "react-responsive";
import { ChatViewContext } from "app";
import { useAppContext } from "app/AppContext";

export const Hero = () => {
  const isNestedPage = useIsNestedPage();
  const { showChat } = useContext(ChatViewContext);
  const isMediumScreen = useMediaQuery({ query: `(min-width: 768px)` });
  const { name, heroSlot, textColorPrimary } = useAppContext();

  const hideTitle = isMediumScreen ? false : showChat;
  return (
    <div
      id="navbar"
      className={`grid grid-cols-8 h-18 md:mb-[24px] ${
        !isMediumScreen && !showChat && "md:pb-[40px]"
      } md:pb-0 `}
    >
      {hideTitle || (
        <div className="order-last md:order-first col-span-8 md:col-span-5 text-blue-900 flex items-center">
          <div
            className={`${
              isNestedPage ? "hidden md:flex flex-row w-full" : ""
            }`}
          >
            <div className={`w-full pt-[16px] md:pt-[0]`}>
              <h1 className="font-['DM_Sans'] text-[40px] md:tracking-wider">
                <strong
                  className={
                    [`text-[#163331]`, `text-[#0b1c47]`].find(
                      (color) => `text-[${textColorPrimary}]` === color,
                    ) || `#0b1c47`
                  }
                >
                  {name}
                </strong>
                <span className="relative bottom-[10px] md:bottom-[18px] tracking-normal bg-white border border-black ml-2 text-[11px] px-[6px] py-[0px] rounded-full text-[11px]">
                  BETA
                </span>
              </h1>
            </div>
          </div>
        </div>
      )}
      <div className="fixed md:relative top-[64px] left-0 md:top-0 w-full z-10 pb-[8px] md:pb-0 col-span-8 md:col-span-3 flex justify-center md:justify-end items-center">
        {heroSlot}
      </div>
    </div>
  );
};
