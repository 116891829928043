import { useAppContext } from "app/AppContext";
import { fetchAllDocuments } from "app/data/fetch-documents";
import { useQuery } from "react-query";

export const useAllLegislationDocuments = ({
  searchValue = "",
  staleTime,
  status = "published",
} = {}) => {
  const { id: contextId } = useAppContext();

  const { data: { data } = { data: {} }, ...queryRest } = useQuery(
    fetchAllDocuments({ context: contextId, staleTime, status }),
  );

  return {
    totalLegislationCount: data[`${contextId}_documents`]?.filter(
      ({ heading_homepage }) => heading_homepage,
    ).length,
    data,
    ...queryRest,
    filteredData: data[`${contextId}_documents`]?.filter(
      (doc) =>
        doc.heading_homepage &&
        Object.values(doc).find((val) => {
          if (!searchValue) return true;

          return typeof val === "string"
            ? val.toLowerCase().includes(searchValue?.toLowerCase())
            : false;
        }),
    ),
  };
};
