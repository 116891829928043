import { useState, useEffect } from "react";
import { BOT_USER_NAME, USERNAME } from "app";
import { useAuth0 } from "@auth0/auth0-react";
import md5 from "md5";
import { useAppContext } from "app/AppContext";

export const useChatState = ({ setShowChat }) => {
  const { demoQuestionResponse } = useAppContext();
  const activeChat = localStorage.getItem("activeChat");
  const { user } = useAuth0();
  const storedChats = Object.fromEntries(
    Object.entries({ ...localStorage }).filter(([key]) =>
      key.startsWith(`chat-history-`),
    ),
  );
  const urlLoadedChat = JSON.parse(
    storedChats[`chat-history-${activeChat}`] || "[]",
  );
  const [chatHistory, setChatHistory] = useState(urlLoadedChat);

  const setLocalStorage = (key, value) => localStorage.setItem(key, value);

  useEffect(() => {
    let timer1 = setTimeout(
      () =>
        !chatHistory.length &&
        setChatHistory([...chatHistory, ...demoQuestionResponse]),
      1 * 1000,
    );
    return () => {
      clearTimeout(timer1);
    };

    // eslint-disable-next-line
  }, []);

  const goToChat = ({ chatId }) => {
    const storedChats = Object.fromEntries(
      Object.entries({ ...localStorage }).filter(([key]) =>
        key.startsWith(`chat-history-`),
      ),
    );

    const foundExistingChat = storedChats[chatId];
    setLocalStorage("activeChat", chatId.replace("chat-history-", ""));
    setChatHistory(
      foundExistingChat
        ? JSON.parse(foundExistingChat)
        : [...demoQuestionResponse],
    );
  };

  const deleteChat = ({ chatId }) => {
    const activeChat = `chat-history-${md5(JSON.stringify(chatHistory))}`;
    if (chatId === activeChat) setChatHistory([...demoQuestionResponse]);
    localStorage.removeItem(chatId);
  };

  const addMessageToChat = ({
    message,
    author = user?.email || USERNAME,
    type = "chat",
    taskId,
    ...rest
  }) => {
    const previousTask = chatHistory.findIndex(
      ({ taskId: previousTaskId, type }) =>
        type === "chat" &&
        author === BOT_USER_NAME &&
        previousTaskId === taskId,
    );

    const newChatHistory =
      previousTask > -1
        ? chatHistory.with(previousTask, {
            message,
            type,
            author,
            timestamp: new Date().getTime(),
            taskId,
            ...rest,
          })
        : [
            ...chatHistory,
            {
              message,
              type,
              author,
              timestamp: new Date().getTime(),
              taskId,
              ...rest,
            },
          ];

    const oldChatId = md5(JSON.stringify(chatHistory));
    const chatId = md5(JSON.stringify(newChatHistory));

    setLocalStorage("activeChat", chatId);
    localStorage.removeItem(`chat-history-${oldChatId}`);
    setLocalStorage(
      `chat-history-${chatId}`,
      JSON.stringify(newChatHistory.slice(-10)),
    );

    setChatHistory(newChatHistory);
  };

  const setChatVisibility = (isVisible) => {
    setShowChat(isVisible);
  };

  return {
    goToChat,
    deleteChat,
    setChatVisibility,
    addMessageToChat,
    setChatHistory,
    chatHistory,
  };
};
