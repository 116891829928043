import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { API_ENDPOINTS, REACT_APP_ENV } from "app/app";
import { useAppContext } from "app/AppContext";
import { useEffect, useRef, useState } from "react";

export const usePostDocumentTest = ({ onSuccess = () => ({}) } = {}) => {
  const { id: context } = useAppContext();
  const [testResults, setTestResults] = useState([]);

  const mutation = useMutation({
    mutationFn: (test) =>
      axios({
        method: "post",
        url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document_test/?context=${context}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: test,
      }),
    onSuccess,
  });

  const postDocuments = async (tests) => {
    try {
      const results = await Promise.allSettled(
        tests.map((test) => mutation.mutateAsync(test)),
      );

      setTestResults(results.map((result) => result?.value.data));
    } catch (e) {
      console.error(e);
    }
  };

  return { runTests: postDocuments, data: testResults };
};

export const useRerunTest = ({ onSuccess = () => ({}), document_id } = {}) => {
  const { id: context } = useAppContext();
  const pollingRef = useRef(null);
  const dataRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenRan, setHasBeenRan] = useState(false);
  const retryCountRef = useRef(0);
  const [testData, setTestData] = useState();

  const { refetch, data } = useGetTestResults(document_id);

  dataRef.current = data;

  const startPolling = () => {
    if (pollingRef.current) clearInterval(pollingRef.current);

    pollingRef.current = setInterval(() => {
      console.log("Polling for task completion...");
      refetch({ force: true }).then(() => {
        const allCompleted = dataRef.current?.tests.every(
          ({ task_status }) => task_status === "completed",
        );

        retryCountRef.current = retryCountRef.current + 1;

        if (allCompleted && retryCountRef.current >= 3) {
          console.log("All tasks completed after at least 3 retries");
          clearInterval(pollingRef.current);
          setTestData(dataRef.current);
          pollingRef.current = null;
          setHasBeenRan(true);
          setIsLoading(false);
        }
      });
    }, 2000);
  };

  const mutation = useMutation({
    mutationFn: (test) =>
      axios({
        method: "patch",
        url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document_test/rerun/${test}?context=${context}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    onSuccess: (data) => {
      refetch({ force: true });
      startPolling();
      onSuccess(data);
    },
  });

  useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  return {
    ...mutation,
    rerun: (...args) => {
      setHasBeenRan(false);
      setIsLoading(true);
      retryCountRef.current = 0;
      mutation.mutate(...args);
    },
    isLoading,
    hasBeenRan,
    data: testData,
  };
};

export const useDeleteDocumentTest = ({ onSuccess = () => ({}) }) => {
  const { id: context } = useAppContext();

  const mutation = useMutation({
    mutationFn: (test) =>
      axios({
        method: "delete",
        url: `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document_test/${test}?context=${context}`,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    onSuccess,
  });

  return { ...mutation, deleteTest: mutation.mutate };
};

export const useGetTestResults = (documentId) => {
  const { id: context } = useAppContext();

  const { data: { data } = {}, refetch } = useQuery({
    queryFn: () =>
      axios(
        `${API_ENDPOINTS[REACT_APP_ENV]}/api/ai_legislation_tracker/legislation_document_test/?context=${context}&document_id=${documentId}&status=active`,
      ),
    queryKey: [`result`, documentId],
    staleTime: 0,
  });

  return { data, refetch };
};
