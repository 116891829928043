import { useState, useRef, useEffect } from "react";
import { Button } from "../button";
import { useAuth0 } from "@auth0/auth0-react";

import { useClickAway } from "react-hook-click-away";
import { Icon } from "../icon";

export const SignIn = ({ noGlyph, buttonClassName, profileClassName }) => {
  const profileRef = useRef(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [userPicture, setUserPicture] = useState();
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  useClickAway(profileRef, () => {
    setShowProfileMenu(false);
  });

  useEffect(() => {
    setUserPicture(user?.picture);
  }, [user, setUserPicture]);

  if (isAuthenticated) {
    return (
      <div className={`relative ${profileClassName}`} ref={profileRef}>
        <button
          className="outlined-none"
          onClick={() => {
            setShowProfileMenu(!showProfileMenu);
          }}
        >
          {userPicture ? (
            <img
              className={`rounded-full w-[24px] h-[24px]`}
              alt="profile"
              src={user?.picture}
              onError={() => setUserPicture(false)}
            />
          ) : (
            <div
              className={`w-[24px] h-[24px] rounded-full bg-[lightgrey] pt-[1px]`}
            >
              <Icon glyphName="person" />
            </div>
          )}
        </button>
        <ul
          className={`
          ${!showProfileMenu && `hidden `}
          bg-white rounded-lg 
          shadow-[0_8px_8px_-4px_rgba(0,0,0,0.08),_0px_0px_8px_2px_rgba(0,0,0,0.08)]
          text-[11px] text-left
          p-[8px] mt-[4px]
          absolute right-[calc(100%-48px)] md:right-[calc(100%-24px)]
          border-[0.031rem] border-[#DAE3F9]
          `}
        >
          <li className="text-[#8086A0] px-[8px] py-[4px]">{user.email}</li>
          <li className="px-[8px] py-[4px] hover:bg-[#EFF6FF]">
            <a
              href="https://app.pentatonic.com"
              target="_blank"
              rel="noreferrer"
              className="block"
            >
              Go to Pentatonic platform
            </a>
          </li>
          <li className=" px-[8px] py-[4px] hover:bg-[#EFF6FF]">
            <button
              className="outlined-none w-full h-full text-left"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Log out
            </button>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <Button
        iconClassName="!text-[24px] w-[24px] pl-[24px]"
        iconGlyph={!noGlyph && "person_outlined"}
        iconOutlined
        secondary
        onClick={() =>
          loginWithRedirect({
            appState: {
              returnTo: window.location.href,
            },
          })
        }
        label="Sign in"
        className={`whitespace-nowrap outlined-none py-[8px] px-[16px] ${buttonClassName}`}
      />
    );
  }
};
