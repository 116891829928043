/* eslint disable */
import React from "react";

export const TermsAndConditions = () => {
  return (
    <div
      className={`max-w-[600px] bg-white rounded h-[100dvh] md:h-[auto] md:mt-[48px] m-[0_auto] p-[24px] space-y-[16px] overflow-y-scroll`}
    >
      <p className="c0">
        <span className="c7 font-bold">Terms &amp; Conditions</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">Last updated September 21, 2023</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">AGREEMENT TO LEGAL TERMS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Pentatonic GmbH, the "Service Provider”, a company registered in
          Germany at Greifswalder Str. 51, 10405 Berlin, Germany, Berlin, Berlin
          10405. VAT number: DE815781616.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider operates the web application
          http://legislation.pentatonic.com, as well as any other related
          products and services that refer or link to these legal terms (the
          "Legal Terms", or “Agreement”) (collectively, the "Service(s)").
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Contact by email at legislation@pentatonic.com or by mail to
          Greifswalder Str. 51, 10405 Berlin, Germany, Berlin, Berlin 10405,
          Germany.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          These Legal Terms constitute a legally binding agreement made between
          “Service User(s)”{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          (an individual or entity that accesses and uses the Services provided
          by the Service Provider, including the Legislation Tracker web
          application, related Chatbot functionality, or any related products
          and services. This may include individuals, customers or entities that
          utilize the services for various purposes, such as accessing and
          interacting with specific files and content, making queries via
          Chatbot functionality and otherwise generally engaging with the
          content and features offered by the Service.)
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          whether personally or on behalf of an entity, and the Service Provider
          (Pentatonic GmbH), concerning access to and use of the Services.
          Service Users agree that by accessing the Services, Service Users have
          read, understood, and agreed to be bound by all of these Legal Terms.{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          IF SERVICE USERS DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN
          SERVICE USERS ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
          MUST DISCONTINUE USE IMMEDIATELY.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated
          herein by reference. The Service Provider reserves the right, in its
          sole discretion, to make changes or modifications to these Legal Terms
          from time to time. The Service Provider will alert Service Users about
          any changes by updating the "Last updated" date of these Legal Terms,
          and Service Users waive any right to receive specific notice of each
          such change. It the responsibility of Service Users to periodically
          review these Legal Terms to stay informed of updates. Service Users
          will be subject to, and will be deemed to have been made aware of, and
          to have accepted, the changes in any revised Legal Terms by Service
          Users continued use of the Services after the date such revised Legal
          Terms are posted.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Services are intended for users who are at least 18 years old.
          Persons under the age
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          of 18 are not permitted to use or register for the Services.{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider recommends that Service Users print a copy of
          these Legal Terms for reference.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">TABLE OF CONTENTS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">1. SERVICES</span>
      </p>
      <p className="c0">
        <span className="c4">2. INTELLECTUAL PROPERTY RIGHTS</span>
      </p>
      <p className="c0">
        <span className="c4">3. SERVICE USER REPRESENTATIONS</span>
      </p>
      <p className="c0">
        <span className="c4">4. SERVICE USER REGISTRATION &nbsp; &nbsp; </span>
      </p>
      <p className="c0">
        <span className="c4">5. CHATBOT FAIR USE POLICY</span>
      </p>
      <p className="c0">
        <span className="c4">6. PROHIBITED ACTIVITIES </span>
      </p>
      <p className="c0">
        <span className="c4">7. SERVICE USER GENERATED CONTRIBUTIONS </span>
      </p>
      <p className="c0">
        <span className="c4">8. CONTRIBUTION LICENSE &nbsp;</span>
      </p>
      <p className="c0">
        <span className="c4">9. SERVICES MANAGEMENT</span>
      </p>
      <p className="c0">
        <span className="c4">10. TERM AND TERMINATION </span>
      </p>
      <p className="c0">
        <span className="c4">11. MODIFICATIONS AND INTERRUPTIONS </span>
      </p>
      <p className="c0">
        <span className="c4">12. GOVERNING LAW</span>
      </p>
      <p className="c0">
        <span className="c4">13. CORRECTIONS</span>
      </p>
      <p className="c0">
        <span className="c4">14. DISCLAIMER</span>
      </p>
      <p className="c0">
        <span className="c4">15. LIMITATIONS OF LIABILITY </span>
      </p>
      <p className="c0">
        <span className="c4">16. INDEMNIFICATION</span>
      </p>
      <p className="c0">
        <span className="c4">
          17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </span>
      </p>
      <p className="c0">
        <span className="c4">18. MISCELLANEOUS </span>
      </p>
      <p className="c0">
        <span className="c4">19. PRIVACY POLICY</span>
      </p>
      <p className="c0">
        <span className="c6">20. </span>
        <span className="c12">
          CALIFORNIA CONSUMER PRIVACY ACT (“CCPA”) COMPLIANCE
        </span>
      </p>
      <p className="c0">
        <span className="c6">21. </span>
        <span className="c12">
          DATA PROTECTION AND DATA PROCESSING (“GDPR”) COMPLIANCE
        </span>
      </p>
      <p className="c0">
        <span className="c4">22. CONTACT</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">1. SERVICES</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Services are not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.),
          so if Service User interactions would be subjected to such laws,
          Service Users may not use the Services.{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Service Users may not use the Services in a way that would violate the
          Gramm-Leach-Bliley Act (GLBA).
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">2. INTELLECTUAL PROPERTY RIGHTS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Intellectual property</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider is the owner or the licensee of all intellectual
          property rights in its Services, including all source code, databases,
          functionality, software, website designs, audio, video, text,
          photographs, and graphics in the Services (collectively, the
          "Content"), as well as the trademarks, service marks, and logos
          contained therein (the "Marks").
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Service Provider Content and Marks are protected by copyright and
          trademark laws (and various
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          other intellectual property rights and unfair competition laws) and
          treaties in the United
        </span>
      </p>
      <p className="c0">
        <span className="c4">States and around the world.</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Content and Marks are provided in or through the Services "AS IS"
          for Service User personal, non-commercial use or internal business
          purpose only.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Use of Services</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Subject to Service User compliance with these Legal Terms, including
          the "PROHIBITED ACTIVITIES" section below, the Service Provider grants
          the Service User a non-exclusive, non-transferable, revocable license
          to:
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">• access the Services; and</span>
      </p>
      <p className="c0">
        <span className="c4">
          • download or print a copy of any portion of the Content to which the
          Service User has properly gained access.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          solely for Service User personal, non-commercial use or internal
          business purpose.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Except as set out in this section or elsewhere in the Legal Terms, no
          part of the Services and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          for any commercial purpose whatsoever, without express prior written
          permission from the Service Provider.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          If Service Users wish to make any use of the Services, Content, or
          Marks other than as set out in this section or elsewhere in the Legal
          Terms, a request should be made to: legislation@pentatonic.com. If the
          Service Provider ever grants Service User permission to post,
          reproduce, or publicly display any part of the Services or Content,
          the Service User must identify the Service Provider as the owner or
          licensor of the Services, Content, or Marks and ensure that any
          copyright or proprietary notice appears or is visible on posting,
          reproducing, or displaying the Content.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider reserves all rights not expressly granted to all
          Service Users in and to the Services, Content, and Marks. Any breach
          of these Intellectual Property Rights will constitute a material
          breach of our Legal Terms and Service User access rights will
          terminate immediately.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Service User submissions</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Please review this section and the "PROHIBITED ACTIVITIES" section
          carefully prior to using the Services to understand the (a) rights
          given to the Service Provider and (b) Service User obligations when
          posting or upload any content through the Services.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c8 c14">Submissions:</span>
        <span className="c4">
          &nbsp;By directly sending the Service Provider any question, comment,
          suggestion, idea, feedback, or other information about the Services
          ("Submissions"), the Service User agrees to assign to the Service
          Provider all intellectual property rights in such Submission. The
          Service User agrees that the Service Provider shall own this
          Submission and be entitled to its unrestricted use and dissemination
          for any lawful purpose, commercial or otherwise, without
          acknowledgment or compensation to the Service User.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c8 c14">
          The Service User is responsible for its posts and uploads:
        </span>
        <span className="c4">
          &nbsp;By sending the Service Provider Submissions through any part of
          the Services the Service User:
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          • confirms that Service User has read and agree with the "PROHIBITED
          ACTIVITIES" and will not post, send, publish, upload, or transmit
          through the Services any Submission that is illegal, harassing,
          hateful, harmful, defamatory, obscene, bullying, abusive,
          discriminatory, threatening to any person or group, sexually explicit,
          false, inaccurate, deceitful, or misleading;
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • to the extent permissible by applicable law, waive any and all moral
          rights to any such Submission;
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • warrant that any such Submission are original to the Service User or
          that the Service User has the necessary rights and licenses to submit
          such Submissions and that the Service User has full authority to grant
          the Service Provider the above-mentioned rights in relation to all
          Submissions; and
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • warrant and represent that Submissions do not constitute
          confidential information.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service User is solely responsible for Service User Submissions
          and expressly agrees to reimburse the Service Provider for any and all
          losses that the Service Provider may suffer because of Service User
          breach of (a) this section, (b) any third party’s intellectual
          property rights, or (c) applicable law.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">3. SERVICE USER REPRESENTATIONS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          By using the Services, Service Users represent and warrant that: (1)
          all registration information Service Users submit will be true,
          accurate, current, and complete; (2) Service Users will maintain the
          accuracy of such information and promptly update such registration
          information as necessary; (3) Service Users have the legal capacity
          and agree to comply with these Legal Terms; (4) Service Users are not
          a minor in the jurisdiction in which Service Users reside; (5) Service
          Users will not access the Services through automated or non-human
          means, whether through a bot, script or otherwise; (6) Service Users
          will not use the Services for any illegal or unauthorized purpose; and
          (7) Service User use of the Services will not violate any applicable
          law or regulation.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          If Service Users provide any information that is untrue, inaccurate,
          not current, or incomplete, the Service Provider has the right to
          suspend or terminate Service User accounts and refuse any and all
          current or future use of the Services (or any portion thereof).
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">4. SERVICE USER REGISTRATION</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Service Users may be required to register to use the Services. Service
          Users agree to keep all related password information confidential and
          will be responsible for all use of Service User accounts and
          passwords. The Service Provider reserves the right to remove, reclaim,
          or change a username selected by a Service User if the Service
          Provider determines, in its sole discretion, that such username is
          inappropriate, obscene, or otherwise objectionable.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">5. CHATBOT FAIR USE POLICY</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c1">
          This Fair Use Policy outlines the acceptable use of the
          ChatGPT-powered Chatbot provided as a Service by the Service Provider
          ("the Chatbot"). By accessing and using the Chatbot, Service Users
          agree to comply with this Policy. Failure to adhere to this policy may
          result in suspension or termination of Service User access to the
          Chatbot.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          This Policy applies to all Service Users engaging with the Chatbot,
          whether accessed directly or through third-party platforms.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c9 c8">Acceptable Use</span>
      </p>
      <p className="c0 c2">
        <span className="c9 c8" />
      </p>
      <p className="c0">
        <span className="c1">
          a. Research Focus: Service Users are permitted to use the Chatbot
          exclusively for research purposes related to the circular economy and
          sustainability. Any use outside this specified domain is strictly
          prohibited.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          b. Interactions: While engaging with the Chatbot, Service Users should
          maintain a respectful and constructive demeanor. Abusive, harassing,
          or inappropriate content or requests will not be tolerated.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c9 c8">Prohibited Use</span>
      </p>
      <p className="c0 c2">
        <span className="c9 c8" />
      </p>
      <p className="c0">
        <span className="c1">
          a. Service Users may not employ the Chatbot for any purpose other than
          circular economy and sustainability research.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          b. Service Users shall not attempt to reverse engineer, disassemble,
          or decompile the Chatbot or its underlying technology.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          c. Overloading, impairing, or undertaking any action that might
          compromise the server infrastructure or degrade the Service quality
          for other Service Users is strictly forbidden.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          d. The Chatbot must not be used to generate content that promotes
          hate, discrimination, violence, or any other illegal activities.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c9 c8">Modifications</span>
      </p>
      <p className="c0 c2">
        <span className="c9 c8" />
      </p>
      <p className="c0">
        <span className="c1">
          The Service Provider reserves the right to alter or terminate the
          Chatbot service without prior notice. Any updates or alterations to
          this Fair Use Policy will be communicated to Service Users through the
          appropriate channels.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          Misuse of the Chatbot, as defined in this Policy, may lead to
          immediate suspension or termination of Service User access at the sole
          discretion of the Service Provider.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c9 c8">Disclaimer</span>
      </p>
      <p className="c0 c2">
        <span className="c9 c8" />
      </p>
      <p className="c0">
        <span className="c12">
          While the Chatbot aims to provide accurate information the Service
          Provider, or any affiliates, do not guarantee the precision,
          completeness, or suitability of the Chatbot's responses for specific
          research purposes.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">6. PROHIBITED ACTIVITIES</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Service Users may not access or use the Services for any purpose other
          than that for which the Service Provider makes the Services available.
          The Services may not be used in connection with any commercial
          endeavors except those that are specifically endorsed or approved by
          the Service Provider.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">Service Users agree not to:</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          • Systematically retrieve data or other content from the Services to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from the Service
          Provider.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Trick, defraud, or mislead the Service Provider and other Service
          Users, especially in any attempt to learn sensitive account
          information such as Service User passwords.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Circumvent, disable, or otherwise interfere with security-related
          features of the Services, including features that prevent or restrict
          the use or copying of any Content or enforce limitations on the use of
          the Services and/or the Content contained therein.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Disparage, tarnish, or otherwise harm, in Service Provider opinion,
          the Service Provider, its affiliates, and/or the Services.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Use any information obtained from the Services in order to harass,
          abuse, or harm another person.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Make improper use of Service Provider support services or submit
          false reports of abuse or misconduct.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Use the Services in a manner inconsistent with any applicable laws
          or regulations.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Engage in unauthorized framing of or linking to the Services.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Services or modifies, impairs, disrupts, alters, or interferes with
          the use, features, functions, operation, or maintenance of the
          Services.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Delete the copyright or other proprietary rights notice from any
          Content.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Attempt to impersonate another Service User or person or use the
          username of another Service User.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Upload or transmit (or attempt to upload or to transmit) any
          material that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as "spyware" or "passive
          collection mechanisms" or "pcms").
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Interfere with, disrupt, or create an undue burden on the Services
          or the networks or services connected to the Services.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Harass, annoy, intimidate, or threaten any Service User or affilate
          employees or agents engaged in providing any portion of the Services
          to the Service User.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Attempt to bypass any measures of the Services designed to prevent
          or restrict access to the Services, or any portion of the Services.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Copy or adapt the Services' software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Except as permitted by applicable law, decipher, decompile,
          disassemble, or reverse engineer any of the software comprising or in
          any way making up a part of the Services.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Services, or use
          or launch any unauthorized script or other software.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Use a buying agent or purchasing agent to make purchases on the
          Services.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Make any unauthorized use of the Services, including collecting
          usernames and/or email addresses of Service Users by electronic or
          other means for the purpose of sending unsolicited email, or creating
          Service Users accounts by automated means or under false pretenses.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Use the Services as part of any effort to compete with us or
          otherwise use the Services and/or the Content for any
          revenue-generating endeavor or commercial enterprise.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Sell or otherwise transfer any Service User profiles.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Use the Services to advertise or offer to sell goods and services.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">7. SERVICE USER GENERATED CONTRIBUTIONS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Services does not offer Service Users to submit or post content.
          The Service Provider may provide the opportunity to create, submit,
          post, display, transmit, perform, publish, distribute, or broadcast
          content and materials to the Service Provider or on the Services,
          including but not limited to text, writings, video, audio,
          photographs, graphics, comments, suggestions, or personal information
          or other material (collectively, "Contributions"). Contributions may
          be viewable by other Service Users and through third-party websites.
          As such, any Contributions Service Users transmit may be treated in
          accordance with the Services' Privacy Policy. When Service Users
          create or make available any Contributions, Service Users thereby
          represent and warrant that:
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of Service
          Users Contributions do not and will not infringe the proprietary
          rights, including but not limited to the copyright, patent, trademark,
          trade secret, or moral rights of any third party.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service Users are the creators and owners of or have the necessary
          licenses, rights, consents, releases, and permissions to use and to
          authorize the Service provider, Services, and other Service Users to
          use Contributions in any manner contemplated by the Services and these
          Legal Terms.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service Users have the written consent, release, and/or permission
          of each and every identifiable individual person in Service User
          Contributions to use the name or likeness of each and every such
          identifiable individual person to enable inclusion and use of Service
          User Contributions in any manner contemplated by the Services and
          these Legal Terms.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions are not false, inaccurate, or misleading.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions are not unsolicited or unauthorized
          advertising, promotional materials, pyramid schemes, chain letters,
          spam, mass mailings, or other forms of solicitation.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions are not obscene, lewd, lascivious,
          filthy, violent, harassing, libelous, slanderous, or otherwise
          objectionable (as determined by the Service Provider).
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions do not ridicule, mock, disparage,
          intimidate, or abuse anyone.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions are not used to harass or threaten (in
          the legal sense of those terms) any other person and to promote
          violence against a specific person or class of people.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions do not violate any applicable law,
          regulation, or rule.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions do not violate the privacy or publicity
          rights of any third party.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions do not violate any applicable law
          concerning child pornography, or otherwise intended to protect the
          health or well-being of minors.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions do not include any offensive comments
          that are connected to race, national origin, gender, sexual
          preference, or physical handicap.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          • Service User Contributions do not otherwise violate, or link to
          material that violates, any provision of these Legal Terms, or any
          applicable law or regulation.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Any use of the Services in violation of the foregoing violates these
          Legal Terms and may result in, among other things, termination or
          suspension of Service User rights to use the Services.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">8. CONTRIBUTION LICENSE</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Service Users and the Service Provider agree that the Service Provider
          may access, store, process, and use any information and personal data
          the Service User provides following the terms of the Privacy Policy
          and Service User choices (including settings).
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          By submitting suggestions or other feedback regarding the Services,
          Service Users agree that the Service Provider can use and share such
          feedback for any purpose without compensation to Service Users.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider does not assert any ownership over Service User
          Contributions. Service Users retain full ownership of their Service
          User Contributions and any intellectual property rights or other
          proprietary rights associated with Service User Contributions. The
          Service Provider is not liable for any statements or representations
          in Service User Contributions provided by Service Users in any area on
          the Services. Service Users are solely responsible for Service User
          Contributions to the Services and Service Users expressly agree to
          exonerate the Service Provider from any and all responsibility and to
          refrain from any legal action against the Service Provider regarding
          Service User Contributions.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">9. SERVICES MANAGEMENT</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider reserves the right, but not the obligation, to:
          (1) monitor the Services for violations of these Legal Terms; (2) take
          appropriate legal action against anyone who, in the Service Provider’s
          sole discretion, violates the law or these Legal Terms, including
          without limitation, reporting such user to law enforcement
          authorities; (3) in the Service Provider’s sole discretion and without
          limitation, refuse, restrict access to, limit the availability of, or
          disable (to the extent technologically feasible) any Service User
          Contributions or any portion thereof; (4) in the Service Provider’s
          sole discretion and without limitation, notice, or liability, to
          remove from the Services or otherwise disable all files and content
          that are excessive in size or are in any way burdensome to Service
          Provider systems; and (5) otherwise manage the Services in a manner
          designed to protect Service Provider rights and property and to
          facilitate the proper functioning of the Services.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">10. TERM AND TERMINATION</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          These Legal Terms shall remain in full force and effect while Service
          Users use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
          LEGAL TERMS, THE SERVICE PROVIDER RESERVES THE RIGHT TO, IN THE
          SERVICE PROVIDER’S SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
          DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP
          ADDRESSES), TO ANY SERVICE USER FOR ANY REASON OR FOR NO REASON,
          INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION. THE SERVICE PROVIDER MAY TERMINATE
          SERVICE USER ACCESS, USE OF, OR PARTICIPATION IN THE SERVICES OR
          DELETE SERVICE USER ACCOUNTS AND ANY CONTENT OR INFORMATION POSTED BY
          SERVICE USERS AT ANY TIME, WITHOUT WARNING, IN THE SERVICE PROVIDER’S
          SOLE DISCRETION.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          If the Service Provider terminates or suspends a Service User account
          for any reason, the Service User is prohibited from registering and
          creating a new account under the Service User name, a fake or borrowed
          name, or the name of any third party, even if the Service User may be
          acting on behalf of the third party. In addition to terminating or
          suspending the Service User account, the Service Provider reserves the
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">11. MODIFICATIONS AND INTERRUPTIONS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider reserves the right to change, modify, or remove
          the contents of the Services at any time or for any reason at the
          Service Provider’s sole discretion without notice. However, the
          Service Provider has no obligation to update any information on
          Service Provider Services. The Service Provider will not be liable to
          Service Users or any third party for any modification, price change,
          suspension, or discontinuance of the Services.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider cannot guarantee the Services will be available
          at all times. The Service Provider may experience hardware, software,
          or other problems or need to perform maintenance related to the
          Services, resulting in interruptions, delays, or errors. The Service
          Provider reserves the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Services at any time or for any
          reason without notice to the Service Users.{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Service Users agree that the Service Provider or it affiliates have no
          liability whatsoever for any loss, damage, or inconvenience caused by
          Service User inability to access or use the Services during any
          downtime or discontinuance of the Services. Nothing in these Legal
          Terms will be construed to obligate the Service Provider or its
          affiliates to maintain and support the Services or to supply any
          corrections, updates, or releases in connection therewith.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">12. GOVERNING LAW</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          These Legal Terms shall be governed by, interpreted, and construed in
          accordance with the laws of the Federal Republic of Germany, with the
          express exclusion of the United Nations Convention on Contracts for
          the International Sale of Goods. The Service Provider and Service User
          consent to the exclusive jurisdiction and venue in German Federal
          Courts (Bundesgerichte) and/or State Courts (Landesgerichte) for the
          purpose of any legal proceeding relating to or arising under these
          Legal Terms.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Notwithstanding the aforementioned, if a Service User’s habitual
          residence is in the European Union (EU), and the Service User is a
          consumer, the Service User additionally possesses the protection
          provided by obligatory provisions of the law in the Service User’s EU
          country of residence. The Service Provider and the Service User
          consent to the non-exclusive jurisdiction of the courts of Germany,
          which means that the Service Users may make a claim related to the
          Service User consumer protection rights in regards to these Legal
          Terms in Germany, or in the EU country in which the Service User
          resides.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">13. CORRECTIONS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. The Service Provider
          reserves the right to correct any errors, inaccuracies, or omissions
          and to change or update the information on the Services at any time,
          without prior notice.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">14. DISCLAIMER</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. SERVICE
          USERS AGREE THAT USE OF THE SERVICES WILL BE AT SERVICE USERS’ SOLE
          RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, THE SERVICE PROVIDER
          DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
          SERVICES AND SERVICE USERS’ USE THEREOF, INCLUDING, WITHOUT
          LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE SERVICE PROVIDER AND ITS
          AFFILIATES MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
          COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES
          OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WILL ASSUME NO
          LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM SERVICE USER ACCESS
          TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF
          SERVICE PROVIDER SECURE SERVERS AND/OR ANY AND ALL PERSONAL
          INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5)
          ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED
          TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS
          OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
          ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
          TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. THE SERVICE
          PROVIDER AND ITS AFFILIATES DO NOT WARRANT, ENDORSE, GUARANTEE, OR
          ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
          BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
          WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
          ADVERTISING, AND THE SERVICE PROVIDER AND ITS AFFILIATES WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
          BETWEEN SERVICE USERS AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
          SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
          MEDIUM OR IN ANY ENVIRONMENT, SERVICE USERS SHOULD USE BEST JUDGMENT
          AND EXERCISE CAUTION WHERE APPROPRIATE.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">15. LIMITATIONS OF LIABILITY</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          IN NO EVENT WILL SERVICE PROVIDER OR AFFILATE DIRECTORS, EMPLOYEES, OR
          AGENTS BE LIABLE TO ANY SERVICE USER OR ANY THIRD PARTY FOR ANY
          DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
          PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA,
          OR OTHER DAMAGES ARISING FROM SERVICE USER INTERACTION WITH, AND USE
          OF, THE SERVICES, EVEN IF THE SERVICE PROVIDER OR ITS AFFILATES HAVE
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
          ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE SERVICE PROVIDER AND
          ITS AFFILATES LIABILITY TO ANY SERVICE USER(S) FOR ANY CAUSE
          WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES
          BE LIMITED TO THE AMOUNT PAID, IF ANY, BY SERVICE USER(S) TO THE
          SERVICE PROVIDER DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF
          ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
          ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
          OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO ANY SERVICE USERS, SOME OR
          ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO THESE
          SERVICE USERS, AND THESE SERVICE USERS MAY HAVE ADDITIONAL RIGHTS.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">16. INDEMNIFICATION</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          All Service Users agree to defend, indemnify, and hold the Service
          Provider harmless, including Service Provider subsidiaries,
          affiliates, and all respective Service Provider officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) use of the
          Services; (2) breach of these Legal Terms; (3) any breach of Service
          User representations and warranties set forth in these Legal Terms;
          (4) Service User violation of the rights of a third party, including
          but not limited to intellectual property rights; or (5) any overt
          harmful act toward any other Service User with whom any Service Users
          may have connected to via the Services. Notwithstanding the foregoing,
          the Service Provider and its affiliates reserve the right, at the
          Service User’s expense, to assume the exclusive defense and control of
          any matter for which the Service User is required to indemnify the
          Service Provider and its affiliates, and all Service Users agree to
          cooperate, at the Service User’s expense, with the Service Provider’s
          defense of such claims. The Service Provider will use reasonable
          efforts to notify the Service User of any such claim, action, or
          proceeding which is subject to this indemnification upon becoming
          aware of it.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          Visiting the Services, sending the Service Provider emails, and
          completing online forms constitute electronic communications. The
          Service User consents to receive electronic communications, and agrees
          that all agreements, notices, disclosures, and other communications
          from the Service provider to Service Users electronically, via email
          and on the Services, satisfy any legal requirement that such
          communication be in writing. SERVICE USERS HEREBY AGREE TO THE USE OF
          ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
          ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
          INITIATED OR COMPLETED BY THE SERVICE PROVIDER OR VIA THE SERVICES.
          Service Users hereby waive any rights or requirements under any
          statutes, regulations, rules, ordinances, or other laws in any
          jurisdiction which require an original signature or delivery or
          retention of non-electronic records, or to payments or the granting of
          credits by any means other than electronic means.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">18. MISCELLANEOUS</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          These Legal Terms and any policies or operating rules posted by the
          Service Provider on the Services or in respect to the Services
          constitute the entire agreement and understanding between the Service
          Provider and the Service User.{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider’s failure to exercise or enforce any right or
          provision of these Legal Terms shall not operate as a waiver of such
          right or provision. These Legal Terms operate to the fullest extent
          permissible by law. The Service Provider may assign any or all of
          Service Provider rights and obligations to others at any time. The
          Service Provider shall not be responsible or liable for any loss,
          damage, delay, or failure to act caused by any cause beyond the
          Service Provider’s reasonable control. If any provision or part of a
          provision of these Legal Terms is determined to be unlawful, void, or
          unenforceable, that provision or part of the provision is deemed
          severable from these Legal Terms and does not affect the validity and
          enforceability of any remaining provisions. There is no joint venture,
          partnership, employment or agency relationship created between any
          Service User and the Service Provider as a result of these Legal Terms
          or use of the Services. All Service Users agree that these Legal Terms
          will not be construed against the Service Provider by virtue of having
          drafted them. All Service Users hereby waive any and all defenses
          based on the electronic form of these Legal Terms and the lack of
          signing by the parties hereto to execute these Legal Terms.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">19. PRIVACY POLICY</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">Last Updated: September 20, 2023</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          This Privacy Policy outlines how the Service Provider collects, uses,
          discloses, and safeguards Service User data with respect to Service
          use. By accessing or using the Service, Service Users consent to the
          practices described in this Policy and are bound by its Terms.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Information Collected</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          a. Personal Information: When Service Users sign up for an account to
          use the Service, or interact with the Chatbot, the Service Provider
          may collect personal information, including but not limited to Service
          User name, email address, username, and other relevant details
          necessary for Service User account creation and usage of the Service.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          b. Chatbot Interactions: The Service Provider collects Service User
          data generated by Service User interactions with the Chatbot,
          including questions asked, responses provided, and any other
          information shared during these conversations.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Information Use</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          a. Account Management: The Service Provider uses Service User
          information to manage Service User accounts, provide access to the
          Legislation Tracker, and enable Service Users to interact with the
          Chatbot.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          b. Chatbot Functionality: Information gathered during Chatbot
          interactions is used to provide accurate responses to Service User
          questions and improve Chatbot performance over time.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          c. Communication: The Service Provider may use Service User contact
          information to send Service Users important updates, notifications,
          and relevant information related to the Services, as well as solicit
          relevant feedback on the Service. The Service Provider also reserves
          the right to contact Service Users to provide information and updates
          on Service Provider products and services.{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Data Sharing</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider does not sell, trade, or rent Service User data
          or information to any third parties. However, we may share Service
          User information with:
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          a. Third-Party Service Providers: The Service Provider may engage
          selected third-party service providers to assist with the operation of
          the Service, such as hosting, maintenance, analytics, and customer
          support. These providers are contractually obligated to protect
          Service User data.
        </span>
      </p>
      <p className="c0">
        <span className="c4">
          b. Legal Compliance: The Service Provider may disclose Service User
          information when required by law, legal process, or governmental
          request, or to protect Service Provider rights, privacy, safety, or
          property, or that of the public.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Data Security</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider implements reasonable security measures to
          protect Service User data from unauthorized access, alteration,
          disclosure, or destruction. However, no method of transmission over
          the internet or electronic storage is entirely secure, and we cannot
          guarantee absolute security.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Service User Choice</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c6">
          a. Access and Correction: Service Users can access, update, or correct
          account information by logging into account settings. If Service Users
          require assistance, they are advised to contact the Service Provider
          at{" "}
        </span>
        <span className="c6">
          <a className="c11" href="mailto:legislation@pentatonic.com">
            legislation@pentatonic.com
          </a>
        </span>
        <span className="c4">.</span>
      </p>
      <p className="c0">
        <span className="c6">
          b. Opt-Out: Service Users can choose to opt-out of receiving
          non-essential communications from the Service Provider by following
          the unsubscribe instructions included in messages, or emailing{" "}
        </span>
        <span className="c6">
          <a className="c11" href="mailto:legislation@pentatonic.com">
            legislation@pentatonic.com
          </a>
        </span>
        <span className="c4">, with ‘Unsubscribe’ in the subject line.</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Retention</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider will retain Service User information only for as
          long as necessary to fulfill the purposes outlined in this Privacy
          Policy and as required by applicable laws.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Children's Privacy</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider does not knowingly collect personal information
          from children. If a Service User believes that the Service Provider
          may have collected personal information from a child, the Service User
          is advised to notify the Service Provider to remove such information.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Changes to the Privacy Policy</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">
          The Service Provider may update this Privacy Policy from time to time
          to reflect changes in practice or for other operational, legal, or
          regulatory reasons. The Service Provider is not obliged to provide
          notice of any material changes.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c3">Contact</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c6">
          Service Users can contact the Service Provider with questions,
          concerns, or requests regarding this Privacy Policy or personal
          information at{" "}
        </span>
        <span className="c6">
          <a className="c11" href="mailto:legislation@pentatonic.com">
            legislation@pentatonic.com
          </a>
        </span>
        <span className="c4">.</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c1">
          20 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CALIFORNIA
          CONSUMER PRIVACY ACT (“CCPA”) COMPLIANCE
        </span>
      </p>
      <p className="c0 c2">
        <span className="c9 c8" />
      </p>
      <p className="c0">
        <span className="c1">
          20.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This clause
          reflects the requirements of the California Consumer Privacy Act of
          2018 and its implementing regulations, as amended or superseded from
          time to time (California Civil Code §§ 1798.100 to 1798.199) (the
          “CCPA”).
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This clause
          applies to the collection, retention, use, and disclosure of Personal
          Information to provide Services to the Service User pursuant to the
          Agreement.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c12">
          20.2.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service
          User is a business and appoints the Service Provider to process the
          Personal Information on behalf of the Service User. The Service User
          is responsible for compliance with the requirements of the CCPA
          applicable to Businesses.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service
          Provider is prohibited from retaining, using, or disclosing the
          Personal Information for any purpose other than for the specific
          purpose of performing the Services specified in the Agreement for the
          Service User, as set out in this clause, or as otherwise permitted by
          the CCPA.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.4 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service
          Provider shall not further collect, sell, or use the Personal
          Information except as necessary to perform the Services. For the
          avoidance of doubt, the Service Provider shall not use the Personal
          Information for the purpose of providing services to another person or
          entity, except that the Service Provider may combine Personal
          Information received from one or more entities to which it provides
          similar services to the extent necessary to detect data security
          incidents, or protect against fraudulent or illegal activity.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.5 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service User
          represents and warrants that it has provided notice that the Personal
          Information is being used or shared consistent with Cal. Civ. Code
          1798.140(t)(2)(C)(i).
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.6 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Supplier
          shall provide reasonable assistance to the Service User in
          facilitating compliance with Consumer rights requests.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.6.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upon direction
          by the Service User and within a commercially reasonable amount of
          time, the Service provider shall delete the Personal Information.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.6.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service
          Provider shall not be required to delete any of the Personal
          Information to comply with a Consumer’s request directed by the
          Service User if it is necessary to maintain such information in
          accordance with Cal. Civ. Code 1798.105(d), in which case the Service
          Provider shall promptly inform the Service User of the exceptions
          relied upon under 1798.105(d) and the Service Provider shall not use
          the Personal Information retained for any other purpose than provided
          for by that exception.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.7 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the event that
          either Party shares Deidentified Information with the other Party, the
          receiving Party warrants that it:
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.7.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;has implemented
          technical safeguards that prohibit reidentification of the Service
          User to whom the information may pertain;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.7.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;has implemented
          business processes that specifically prohibit reidentification of the
          information;{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.7.3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;has implemented
          business processes to prevent inadvertent release of Deidentified
          Information; and
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.7.4 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;will make no
          attempt to reidentify the information.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.8 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the event that
          either Party transfers to a Third Party the Personal Information of a
          Service User as an asset that is part of a merger, acquisition,
          bankruptcy, or other transaction in which the Third Party assumes
          control of all or part of such Party to the Agreement, that
          information shall be used or shared consistently with applicable law.
          If a Third Party materially alters how it uses or shares the Personal
          Information of a Service User in a manner that is materially
          inconsistent with the promises made at the time of collection, it
          shall provide prior notice of the new or changed practice to the
          Service User in accordance with applicable law.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          20.9 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notwithstanding
          any provision to the contrary of the Agreement, the Service Provider
          may cooperate with law enforcement agencies concerning conduct or
          activity that it reasonably and in good faith believes may violate
          international, federal, state, or local law.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          21 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DATA PROTECTION AND
          DATA PROCESSING (“GDPR”) COMPLIANCE
        </span>
      </p>
      <p className="c0 c2">
        <span className="c1" />
      </p>
      <p className="c0">
        <span className="c1">
          21.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As of 25 May 2018
          the General Data Protection Regulation (EU) 2016/679 (“GDPR”) will
          apply in the EEA.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service User
          warrants that it has obtained Personal Data supplied to the Service
          Provider in a compliant manner and has all necessary authorisation,
          approvals, contracts, consents and notices in place to enable lawful
          use, processing and transfer of the Personal Data to and by the
          Service Provider, its agents and suppliers as required by the
          Agreement. In addition, all notices, information and communication
          (including the Service Provider’s Privacy Policy) required for the
          Service Provider’s use and processing of Personal Data as described in
          the Agreement (including the transfer of Personal Data to third
          parties) where it acts as a Controller, shall be provided to the
          relevant Data Subject, as directed by the Service Provider from time
          to time. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Where the Service
          Provider acts as a Processor for the Service User in the processing of
          End User Personal Data, and for the duration of the Agreement, the
          following clauses shall apply:
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Service
          Provider will process Personal Data relating to the End Users (and
          related parties) on the written instructions of the Service User, in
          accordance with these Conditions, for the performance of the Agreement
          and for any other agreed purposes, unless required otherwise by the
          laws of the United Kingdom or any member of the European Union, if
          applicable;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Service
          Provider will ensure that it has in place appropriate technical and
          organisational measures to protect against unauthorised or unlawful
          processing of Personal Data and against accidental loss or destruction
          of, or damage to, Personal Data, appropriate to the harm that might
          result from the unauthorised or unlawful processing or accidental
          loss, destruction or damage and the nature of the data to be
          protected, having regard to the state of technological development and
          the cost of implementing any measures (those measures may include,
          where appropriate, pseudonymising and encrypting Personal Data,
          ensuring confidentiality, integrity, availability and resilience of
          its systems and services);
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Service
          Provider will ensure that all personnel who have access to and/or
          process Personal Data are obliged to keep the Personal Data
          confidential;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.4 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Service
          Provider will assist the Service User, at the Service User’s cost, in
          responding to any request from a Data Subject and in ensuring
          compliance with its legal obligations under the Data Privacy
          Legislation with respect to security, breach notifications, impact
          assessments and consultations with supervisory authorities or
          regulators;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Service
          Provider will notify the Service User without undue delay on becoming
          aware of a Personal Data Breach or any request from a supervisory
          authority or regulator for information or any form of investigation in
          relation to processing carried out under these Conditions;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Service
          Provider will at the written direction of the Service User, delete or
          return Personal Data and copies thereof to the Client on termination
          of these Conditions unless required by applicable law or by regulation
          to store the Personal Data;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to the extent
          required by Data Privacy Legislation, the Service Provider will
          maintain complete and accurate records and information to demonstrate
          its compliance with this clause 23.3, which shall be promptly provided
          to the Service User on request, and to the extent audit obligations
          mandated by Data Privacy Legislation may not be otherwise satisfied,
          audits and inspections will be conducted during regular business
          hours, without interfering with the Service Provider’s operations and
          upon reasonable prior written notice. The Service Provider may
          determine that such audits and inspections are subject to the
          execution of a confidentiality undertaking. The parties shall, acting
          reasonably, agree the scope and parameters of any such audit. The
          Service Provider shall be entitled to reject auditors which are
          competitors of the Supplier. The Service User shall inform the Service
          Provider without undue delay and comprehensively about any errors or
          irregularities detected during an audit; and
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.3.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;where the
          Service Provider appoints any third party processor of Personal Data
          under these Conditions, prior to such appointment the Service Provider
          will enter into a written agreement with the third-party processor,
          incorporating terms which are no less stringent than those set out in
          these Conditions. Where there is a change to any such third party
          processors, the Service Provider will notify the Service User
          directly.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service
          Provider may, at any time on not less than 30 days’ notice, revise
          clause 23.3 by replacing it with any applicable controller to
          processor standard clauses or similar terms forming part of an
          applicable certification scheme (which shall apply when replaced by
          attachment to the Agreement).
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service
          Provider acts as a Controller if:
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;it collects
          Personal Data in connection with the Service User’s operations (such
          as Personal Data relating to the Service User’s employees);
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;it processes End
          User Personal Data provided by the Service User for:
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          (a) carrying out fraud, anti-money laundering, sanctions and any other
          checks and investigating and prosecuting fraud, money laundering or
          sanctions violations in connection with the establishment and
          maintenance of a client relationship and provision of services;
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          (b) compliance with legal and regulatory obligations;
        </span>
      </p>
      <p className="c0">
        <span className="c1">(c) anonymization and/or data analytics; or</span>
      </p>
      <p className="c0">
        <span className="c1">
          (d) the fulfillment of the Agreement, and is required to transfer such
          Personal Data to third parties who are acting as a Data Controller,
          such as carriers or Manufacturers, but will not make such Personal
          Data available to third parties, unless such third parties have
          committed themselves to confidentiality and compliance with Data
          Privacy Legislation. The Service Provider implements and maintains
          appropriate technical and organisational measures in line with the
          requirements of the Data Privacy Legislation.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Service User
          acknowledges that
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;it has read and
          understood the Supplier’s Privacy Policy and agrees at all times to
          comply with it; and
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          21.6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data which is
          not Personal Data, and Personal Data which has been anonymised, may be
          used by the Service Provider as it may determine.
        </span>
      </p>
      <p className="c0">
        <span className="c12">
          21.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In order to accept
          an Order from the Service User it may be necessary for the Service
          Provider to verify the Service User’s financial standing and the
          Service Provider reserves the right to obtain information on the
          Service User’s creditworthiness from credit reporting agencies or
          credit insurers (which will keep a record of any search made) and may
          disclose the results of those enquires, searches and references and
          any information given by the Service User to any credit reference
          agency or to any other company in any corporate group of which it is a
          member.
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">22. CONTACT</span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c12 c21">
          Questions pertaining to these Legal Terms, or correspondence around
        </span>
        <span className="c4">
          &nbsp;complaints and resolutions regarding the Services should be
          addressed to:
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4" />
      </p>
      <p className="c0">
        <span className="c4">Pentatonic GmbH </span>
      </p>
      <p className="c0">
        <span className="c4">Greifswalder Str. 51, 10405 Berlin, Germany</span>
      </p>
      <p className="c0">
        <span className="c4">Berlin, Berlin 10405</span>
      </p>
      <p className="c0">
        <span className="c4">Germany</span>
      </p>
      <p className="c0">
        <span className="c6">legislation@pentatonic.com</span>
      </p>
      <p className="c2 c20">
        <span className="c1" />
      </p>
    </div>
  );
};
