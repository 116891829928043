import React from "react";
import UKFlag from "app/assets/ukFlagObj.svg";
import USFlag from "app/assets/usFlagObj.svg";
import EUFlag from "app/assets/euFlagObj.svg";
import { Tag } from "app/components/tag";

export const FlagsObject = {
  US: USFlag,
  UK: UKFlag,
  EU: EUFlag,
};

export const Flag = ({ jurisdiction, className = "" }) => {
  return (
    <Tag
      className={`flex items-center ${className}`}
      label={
        <>
          <img src={FlagsObject[jurisdiction]} alt={`${jurisdiction} flag`} />
          <span className="pl-1 pt-[1px]">{jurisdiction}</span>
        </>
      }
    />
  );
};
