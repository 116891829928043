import React from "react";
import { SignIn } from "app/components/sign-in-button";
import { useAuth0 } from "@auth0/auth0-react";

export const SignInBlocker = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div
      className={`
        w-full 
        mt-[48px]
        rounded-lg
        bg-gradient-to-r from-[#DAF2F9] via-[#F0DAF9] to-[#D5F8F1]
        animate-[slideInDelayed_2s_ease-in-out]
      `}
    >
      <div
        className={`
          w-[calc(100%-4px)] h-[calc(100%-4px)] 
          relative top-[2px] left-[2px]
          px-[24px] py-[16px]
          bg-white
          rounded-[6px]
          flex items-center justify-between
        `}
      >
        <div>
          <strong className="text-[18px]  font-['DM_Sans']">
            Want to ask more?
          </strong>
          <p className="text-[#464C64] mt-[4px]">
            To ask more legislation questions, please{" "}
            <a
              className="text-[#173991]"
              href="/"
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.href,
                  },
                })
              }
            >
              sign up
            </a>{" "}
            or{" "}
            <a
              className="text-[#173991]"
              href="/"
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.href,
                  },
                })
              }
            >
              sign in
            </a>{" "}
            to continue.
          </p>
        </div>
        <div>
          <SignIn noGlyph />
        </div>
      </div>
    </div>
  );
};
