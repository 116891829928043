/* eslint-disable react/no-unescaped-entities */
import React from "react";

export const Disclaimer = () => {
  return (
    <div
      className={`max-w-[600px] bg-white rounded h-[100dvh] md:h-[auto] md:mt-[48px] m-[0_auto] p-[24px] space-y-[16px] overflow-y-scroll`}
    >
      <p className="c5">
        <span className="c8 c16 font-bold">General Disclaimer</span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c8 font-semibold">Definition of terms</span>
        <span className="c1">
          <br />
        </span>
      </p>
      <p className="c5">
        <span className="c9 c8 font-medium">Service:</span>
      </p>
      <ul className="c15 lst-kix_8ze37gbe0jhm-0 start">
        <li className="c13">
          <span className="c1">
            "Service" refers to the range of features, functions, and
            capabilities offered by the Legislation Tracker web application via
            https://legislation.pentatonic.com, and any related products and
            services.
          </span>
        </li>
        <li className="c13">
          <span className="c1">
            These services include, but are not limited to, providing Service
            Users with the ability to gain insights into existing and upcoming
            sustainability, environmental and circular economy legislation,
            access and links to legislation documentation, context and
            commentary around such legislation and interaction via a Chatbot
            function.
          </span>
        </li>
        <li className="c13">
          <span className="c1">
            The Service encompasses the entire online platform, tools, and
            resources made available to Service Users.
          </span>
        </li>
        <li className="c13">
          <span className="c12">
            Service Users access and utilize the Service subject to all
            stipulations detailed within the Terms and Conditions.
          </span>
        </li>
      </ul>
      <p className="c22">
        <span className="c9 c8 font-medium">Service User:</span>
      </p>
      <ul className="c15 lst-kix_cyk99960font-0 start">
        <li className="c5 c18">
          <span className="c1">
            An individual or entity that accesses and uses the services provided
            by the Service Provider, including the Legislation Tracker web
            application or any related products and services.
          </span>
        </li>
        <li className="c5 c18">
          <span className="c1">
            Service Users may include individuals, customers or entities that
            utilize the services for various purposes, such as accessing and
            interacting with specific files and content, making queries via the
            Chatbot functionality and otherwise generally engaging with the
            content and features offered by the Service.
          </span>
        </li>
      </ul>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c8 c9 font-medium">Service Provider:</span>
      </p>
      <ul className="c15 lst-kix_mkshtyttudwj-0 start">
        <li className="c5 c18">
          <span className="c12">The Service Provider refers to </span>
          <span className="c6">Pentatonic GmbH,</span>
          <span className="c1">
            &nbsp;the company registered in Germany, which owns, operates, and
            provides the Services offered through the web application
            https://legislation.pentatonic.com, and all related products and
            Services.
          </span>
        </li>
        <li className="c5 c18">
          <span className="c12">
            The Service Provider is responsible for the development,
            maintenance, and provision of the Services, as well as enforcing the
            terms and conditions outlined in the terms of service.
          </span>
        </li>
      </ul>
      <p className="c0 c2">
        <span className="c4"></span>
      </p>
      <p className="c5">
        <span className="c8 font-semibold">Disclaimer</span>
      </p>
      <p className="c5 c2">
        <span className="c23 c12"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The legislation, associated documents, and Interactive Chatbot hosted
          on this web application Service, https://legislation.pentatonic.com,
          are provided for informational purposes only and are not an official
          or canonical source of information.{" "}
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Service Provider strives to ensure the accuracy of the information
          presented and makes no guarantees, expressly or implied, as to the
          completeness, timeliness, or accuracy of the information provided.
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          This Service has been built using the ChatGPT API, so all Service
          Users are bound by OpenAI’s terms and conditions, accessible at
          https://openai.com/policies.
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Service Provider, and any affiliates, will not be held responsible
          for any Service User sharing proprietary and non-proprietary
          information with the web application, which may be used by OpenAI to
          improve the ChatGPT suite of Large Language Model (LLM) products or
          any other Machine Learning product in the future.
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Furthermore, please note that this Service is not affiliated with any
          governmental agency, and the legislation presented may not be the most
          current version available.{" "}
        </span>
      </p>
      <p className="c5 c2">
        <span className="c9 c8 font-medium"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Service Provider strongly recommends that Service Users consult
          with a licensed attorney or the official source to verify the accuracy
          and completeness of any legislation before taking any action based on
          any information presented via this Service.{" "}
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          Service Users acknowledge and agree that the Service Provider and its
          affiliates are not liable for any damages or losses that may arise
          from Service User reliance on any information presented. Service Users
          also agree to hold harmless and indemnify the Service Provider and its
          affiliates from any claims or liabilities arising from any use of this
          Service.
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Service Provider will not sell Service User data to any
          third-party entities. Service User privacy and data security is of the
          utmost important to the Service Provider. The Service Provider is
          committed to safeguarding Service User information in accordance with
          our Privacy Policy.
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Service Provider reserves the right to use prompts entered by
          Service Users during Service use to improve the Service and its
          Chatbot functionality.
        </span>
      </p>
      <p className="c2 c5">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c1">
          The Service Provider reserves the right to contact Service Users to
          solicit feedback on the Service. The Service Provider also reserves
          the right to contact Service Users to provide information and updates
          on Service Provider’s products and services.{" "}
        </span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c12">
          Service Users can opt-out from receiving contact from the Service
          Provider by emailing{" "}
        </span>
        <span className="c12 c24">
          <a className="c11" href="mailto:legislation@pentatonic.com">
            legislation@pentatonic.com
          </a>
        </span>
        <span className="c1">, with ‘Unsubscribe’ in the subject line.</span>
      </p>
      <p className="c5 c2">
        <span className="c1"></span>
      </p>
      <p className="c5">
        <span className="c12">
          As per the Fair Use clause in the Terms and Conditions, the Service
          Provider reserves the right to limit or suspend access to the Service
          for any Service User engaging in activities deemed inappropriate or
          excessive by the Service Provider or its affiliates. Any violation may
          result in appropriate action, up to and including termination of
          Service access, without recourse or appeal by the Service User.{" "}
        </span>
      </p>
      <p className="c0 c2">
        <span className="c4"></span>
      </p>
      <hr />
      <p className="c0 c2">
        <span className="c4"></span>
      </p>
      <p className="c0 c2">
        <span className="c4"></span>
      </p>
    </div>
  );
};
