import React, { useState, useEffect } from "react";
import { Icon } from "../icon";

function NavButton({ content, onClick, active, disabled, bordered = true }) {
  return (
    <button
      className={`flex flex-col
              items-center 
              justify-center 
              w-9 h-9 
              transition-colors rounded-[4px] border border-[#DAE3F9]
              hover:bg-[#EFF6FF]
      ${!active && "bg-white"}
      ${active ? "bg-[#DAE3F9] text-[#282F44]" : " text-[#464C64]"}
      ${!disabled ? " cursor-pointer" : " cursor-not-allowed"}
      ${bordered ? "border" : "border-none"}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
}

function PaginationNav({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  scrollTop,
}) {
  const [activePage, setActivePage] = useState(0);
  const [expandedStart, setExpandedStart] = useState(false);
  const [expandedEnd, setExpandedEnd] = useState(false);
  useEffect(() => {
    if (activePage >= pageCount) {
      const lastPageIndex = pageCount - 1;
      setActivePage(lastPageIndex);
      gotoPage(lastPageIndex);
    }
  }, [pageCount, activePage, gotoPage]);
  const forward = () => {
    gotoPage(activePage + 1);
    setActivePage(activePage + 1);
    scrollTop();
  };

  const backward = () => {
    gotoPage(activePage - 1);
    setActivePage(activePage - 1);
    scrollTop();
  };

  const handleClick = (value) => {
    setActivePage(value);
    gotoPage(value);
    scrollTop();
  };

  const renderPageLinks = () => {
    let pages = [];

    if (pageCount > 1) {
      pages.push(0);
    }

    // Calculate middle pages
    let startMiddle = Math.max(1, pageIndex - 1);
    let endMiddle = Math.min(pageCount - 2, pageIndex + 1);

    if (startMiddle <= 2) {
      startMiddle = 1;
      endMiddle = Math.min(3, pageCount - 2);
    } else if (endMiddle >= pageCount - 3) {
      endMiddle = pageCount - 2;
      startMiddle = Math.max(1, pageCount - 4);
    }

    if (expandedStart) {
      for (let i = 1; i < startMiddle; i++) {
        pages.push(i);
      }
    } else if (startMiddle > 1) {
      pages.push("...");
    }

    for (let i = startMiddle; i <= endMiddle; i++) {
      pages.push(i);
    }

    if (expandedEnd) {
      for (let i = endMiddle + 1; i < pageCount - 1; i++) {
        pages.push(i);
      }
    } else if (endMiddle < pageCount - 2) {
      pages.push("...");
    }

    // Always add the last page
    pages.push(pageCount - 1);

    return pages.map((page, i) => {
      if (page === "...") {
        return (
          <li key={`${page} ${i}`}>
            <NavButton
              content={`...`}
              onClick={() => {
                if (i < 2) {
                  setExpandedStart(true);
                } else {
                  setExpandedEnd(true);
                }
              }}
            />
          </li>
        );
      } else {
        return (
          <li key={`${page} ${i}`}>
            <NavButton
              content={page + 1}
              onClick={() => {
                setExpandedStart(false);
                setExpandedEnd(false);
                handleClick(page);
              }}
              active={activePage === page}
            />
          </li>
        );
      }
    });
  };

  return (
    <ul className="flex flex-wrap justify-center gap-[8px]">
      <li>
        <NavButton
          content={<Icon glyphName="chevron_left" />}
          bordered={false}
          onClick={() => {
            setExpandedStart(false);
            setExpandedEnd(false);
            backward();
          }}
          disabled={!canPreviousPage}
        />
      </li>
      {renderPageLinks()}
      <li>
        <NavButton
          content={<Icon glyphName="chevron_right" />}
          onClick={() => {
            setExpandedStart(false);
            setExpandedEnd(false);
            forward();
          }}
          disabled={!canNextPage}
          bordered={false}
        />
      </li>
    </ul>
  );
}

function PaginationNavPresentation(props) {
  return (
    <div className="flex gap-3 flex-wrap p-[24px] justify-center w-full bottom-1">
      <PaginationNav
        gotoPage={props.setCurrentPage}
        canPreviousPage={props.currentPage > 0}
        canNextPage={props.currentPage < props.totalPages - 1}
        pageCount={props.totalPages}
        pageIndex={props.currentPage}
        scrollTop={props.scrollTop}
      />
    </div>
  );
}

export { PaginationNavPresentation };
