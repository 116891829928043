import React from "react";
import { Link, useParams } from "react-router-dom";
import { Flag } from "app/constants/flag-object";
import { Icon } from "app/components/icon";
import { Tag } from "app/components/tag";
import { Button } from "app/components/button";
import { useQuery } from "react-query";
import { fetchDocumentById } from "app/data/fetch-documents";
import { useAppContext } from "app/AppContext";

const tags = ["topic", "jurisdiction", "us_state", "eu_country", "status"];

export const Legislation = () => {
  const { legislationId } = useParams();
  const {
    id: contextId,
    documentReferenceKey,
    documentTypeSingular,
    documentTypePlaural,
    yearLabel,
  } = useAppContext();

  const { data = {} } = useQuery(
    fetchDocumentById({ id: legislationId, context: contextId }),
  );

  const {
    heading_homepage: heading,
    heading_inner: fullLegislationName,
    description,
    url,
    s3_download_url,
    year,
    ...legislation
  } = data?.data || {};

  const foundTagValues = Object.entries(legislation)
    .reduce((foundValues, [tagName, tagValue]) => {
      if (tags.includes(tagName)) return [...foundValues, tagValue];
      return foundValues;
    }, [])
    .filter(Boolean);

  return (
    <div className="left flex-1 text-[13px] text-[#282F44]">
      <span className=" text-ellipsis whitespace-nowrap overflow-hidden block">
        <Link to={"/legislation"}>
          <span className="inline md:hidden">
            <Icon
              glyphName="chevron_left"
              className="relative top-[4px] !inline text-[#595F77]"
            />
            Back to{" "}
          </span>
          All {documentTypePlaural}
        </Link>
        <span className="hidden md:inline text-[#595F77]">
          {" "}
          <Icon
            glyphName="chevron_right"
            className="relative top-[4px] !inline text-[#595F77]"
          />
          {heading}
        </span>
      </span>
      <h2 className="text-[29px] md:leading-[40px] pt-[24px]">{heading}</h2>

      <div className="flex flex-wrap pt-[8px]">
        {foundTagValues.map((tag) =>
          legislation?.jurisdiction === tag ? (
            <Flag
              key={tag}
              jurisdiction={legislation.jurisdiction}
              className="bg-white border-0"
            />
          ) : (
            <Tag key={tag} label={tag} className="bg-white border-0" />
          ),
        )}
      </div>

      <p className="whitespace-pre-line pt-[24px] font-['DM_Sans'] text-[14px] leading-7 text-[#464C64]">
        {description}
      </p>
      <p className="flex flex-col space-y-3 pt-[24px]">
        <strong className="text-[18px] font-medium">Key Information</strong>
        {year && (
          <span>
            <strong className="font-medium">{yearLabel}: </strong>
            <span>{year}</span>
          </span>
        )}

        {legislation[documentReferenceKey] && (
          <span>
            <strong className="font-medium">Ref No: </strong>
            <span>{legislation[documentReferenceKey]}</span>
          </span>
        )}

        {fullLegislationName && (
          <span>
            <strong className="font-medium">
              Full {documentTypeSingular} Name:{" "}
            </strong>
            <span>{fullLegislationName}</span>
          </span>
        )}
      </p>
      <div className="flex pt-[24px]">
        <Button
          iconGlyph="open_in_new"
          label={`${documentTypeSingular} PDF`}
          onClick={() => {
            window.open(s3_download_url, "_blank");
          }}
          className={`
            !rounded border-0 
            !ml-0 mr-[8px] 
            grow md:grow-0 justify-center md:justify-start
          `}
        />
        <Button
          iconGlyph="open_in_new"
          label={`${documentTypeSingular} Source`}
          onClick={() => {
            window.open(url, "_blank");
          }}
          className={`
            !rounded border-0 
            !ml-0 mr-[8px] 
            grow md:grow-0 justify-center md:justify-start
          `}
        />
      </div>
    </div>
  );
};
