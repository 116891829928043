import React, { createContext, useContext } from "react";
import regenerationVc from "app/assets/Regeneration-VC.png";
import pentatonicLogo from "app/assets/Pentatonic-logo.svg";
import topics from "app/data/sectors.json";
import status from "app/data/status.json";
import cops from "app/data/cops.json";
import jurisdictions from "app/data/jurisdictions.json";

const AppContext = createContext();

const copilotContext = {
  id: `copilot`,
  name: `Copilot`,
  documentReferenceKey: "cop_document_id",
  backgroundStyle: `h-[100dvh] min-h-[100dvh] overflow-hidden md:overflow-auto bg-gradient-to-br md:bg-gradient-to-r from-[#eb9491] via-[#ffffff] to-[#72A0C1]`,
  heroSlot: <div />,
  textColorPrimary: `#163331`,
  documentTypeSingular: "US Legislation",
  documentTypePlaural: "US Legislations",
  documentGroup: "Copilot",
  groupFilterItems: cops,
  demoQuestionResponse: [
    {
      message: "Questions to ask...",
      type: "context",
    },
    {
      message:
        "Click on any of the questions below or ask your own to have them answered by Copilot.",
      type: "question",
    },
    {
      message: "What's the liklihood of US12345 passing?",
      type: "option",
    },
    {
      message: "How are we standing with adherance to the Paris Agreement?",
      type: "option",
    },
  ],
  yearLabel: "Year of Conference",
};

const copContextSettings = {
  id: `cop`,
  name: `COP Tracker`,
  documentReferenceKey: "cop_document_id",
  backgroundStyle: `h-[100dvh] min-h-[100dvh] overflow-hidden md:overflow-auto bg-gradient-to-br md:bg-gradient-to-r from-[#F9FFD6] via-[#B8E0DE] to-[#F9FFD6]`,
  heroSlot: (
    <a
      href="https://legislation.pentatonic.com"
      target="_blank"
      className="gap-4 items-center flex"
      rel="noreferrer"
    >
      <span className="text-sm text-[#464C64] font-normal">Powered by</span>
      <img
        className="h-[16px] hidden md:block"
        src={pentatonicLogo}
        alt="Pentatonic"
      />
      <span className="text-xl font-bold text-[#112B6C]">
        Legislation Tracker
      </span>
    </a>
  ),
  textColorPrimary: `#163331`,
  documentTypeSingular: "COP Document",
  documentTypePlaural: "COP Documents",
  documentGroup: "COP",
  statusFilterItems: status,
  demoQuestionResponse: [
    {
      message: "Questions to ask...",
      type: "context",
    },
    {
      message:
        "Click on any of the questions below or ask your own to have them answered by the COP chatbot.",
      type: "question",
    },
    {
      message:
        "Can you summarise the draft conclusions from COP28 Standing Committee on Finance?",
      type: "option",
    },
    {
      message:
        "How has recognition for human-induced climate change changed throughout the course of the COPs?",
      type: "option",
    },
    {
      message: "How are gender and climate change covered under COP 22?",
      type: "option",
    },
  ],
  yearLabel: "Year of Conference",
};

const contextSettings = {
  default: {
    id: `legislation`,
    name: `Legislation Tracker`,
    documentReferenceKey: "legislation_id",
    backgroundStyle: `h-[100dvh] min-h-[100dvh] overflow-hidden md:overflow-auto bg-gradient-to-br md:bg-gradient-to-r from-[#DAF2F9] via-[#F0DAF9] to-[#D5F8F1]`,
    heroSlot: (
      <>
        <p className="text-[10px] md:text-base pr-4 pt-1 font-['DM_Sans'] text-[#464C64]">
          In collaboration with
        </p>
        <a href="https://regeneration.vc/" target="_blank" rel="noreferrer">
          <img
            className="h-[16px] md:h-[23px]"
            src={regenerationVc}
            alt="Regeneration VC"
          />
        </a>
      </>
    ),
    textColorPrimary: `#0b1c47`,
    documentTypeSingular: "legislation",
    documentTypePlaural: "pieces of legislation",
    documentGroup: "Topic",
    groupFilterItems: topics,
    locationFilterItems: jurisdictions,
    statusFilterItems: status,
    demoQuestionResponse: [
      {
        message: "Questions to ask...",
        type: "context",
      },
      {
        message:
          "Click on any of the questions below or ask your own to have them answered by the legislation tracker chatbot.",
        type: "question",
      },
      {
        message: "Explain in detail the principles of the circular economy",
        type: "option",
      },
      {
        message:
          "Can you summarise Italy Legislative Decree Implementing the Directive on Packaging & Packaging Waste and how it will affect food companies",
        type: "option",
      },
    ],
    yearLabel: "Year",
  },
  "copilot-dev": copilotContext,
  cop: copContextSettings,
  "cop-dev": copContextSettings,
};

export const AppProvider = ({ children }) => {
  const [context] = window.location.host.split(".");
  const settings = contextSettings[context] || contextSettings.default;

  document.title = settings.name;

  return <AppContext.Provider value={settings}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
