import React, { useState } from "react";
import classNames from "classnames/bind";
import { Icon } from "app/components/icon";
import { USERNAME } from "app";
import { useAuth0 } from "@auth0/auth0-react";

const cx = classNames.bind();

export const ChatMessages = ({
  message: chatMessageObject,
  chatLength,
  sendChatMessage,
  index,
}) => {
  const [hasImage, setHasImage] = useState(true);
  const { message, author, type, isResponse } = chatMessageObject;
  const { user } = useAuth0();

  const onMessageClick = ({ type, message }) => {
    if (type === "option") {
      sendChatMessage({ message, type: "chat" });
    }
  };
  return (
    <div
      data-testId="chat-message"
      className={cx(
        index === 0 && "mt-auto",
        type === "chat" && "my-[12px] flex-row-reverse",
        type === "option" && "my-[12px]",
        type === "question" && "mt-0 mb-[12px]",
        type === "context" && "my-[8px]",
        index === chatLength - 1 && "mb-0",
        isResponse && "!flex-row",
        "flex justify-between",
      )}
    >
      {type === "chat" && (
        <div
          className={cx(
            isResponse &&
              "bg-gradient-to-br from-[#DAF2F9] via-[#F0DAF9] to-[#D5F8F1] text-[#173991]",
            !isResponse && "bg-[#DAE3F9]",
            "rounded-full w-[32px] h-[32px] flex justify-center	flex-wrap	content-center",
            "shadow-[0px_2px_2px_-1px_rgba(0,0,0,0.16),_0px_0px_2px_1px_rgba(0,0,0,0.08)]",
          )}
        >
          {author === USERNAME || author === user?.email ? (
            user && hasImage ? (
              <img
                className={`rounded-full w-[32px] h-[32px]`}
                alt="profile"
                src={user.picture}
                onError={() => setHasImage(false)}
              />
            ) : (
              <Icon filled glyphName="person" className="text-[#595F77]" />
            )
          ) : (
            <span>
              {author
                .split(" ")
                .map((nameSegment) => nameSegment.charAt(0).toUpperCase())}
            </span>
          )}
        </div>
      )}
      <div
        role="none"
        {...(type === "option"
          ? {
              tabIndex: 0,
              role: "button",
              onClick: () => onMessageClick({ type, message }),
            }
          : {})}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            onMessageClick({ type, message });
          }
        }}
        className={cx(
          "w-full",
          type === "chat" &&
            "p-[16px] shadow-[0px_8px_8px_-4px_rgba(0,0,0,0.08),_0px_0px_8px_2px_rgba(0,0,0,0.08)] border rounded-[16px] text-[13px] text-[#464C64] leading-[24px] border-[#DAE3F9] !w-[calc(100%-48px)] bg-white",
          type === "option" &&
            "p-[16px] border border-[#060E23] rounded-[16px] text-[13px] text-[#464C64] cursor-pointer leading-[24px] bg-white",
          type === "question" && "text-[#464C64]",
          type === "context" && "text-[18px]",
        )}
      >
        {message.split(/\n|\\n/).map((para, i) => (
          <p key={para + i} className={i > 1 && `mt-[8px]`}>
            {para.split(/\*\*(.*?)\*\*/g).map((text, j) => {
              if (j % 2 === 1) {
                // This is text enclosed in **, so wrap it in <strong> tags
                return <strong key={text + j}>{text}</strong>;
              } else {
                // This is regular text
                return text;
              }
            })}
          </p>
        ))}
      </div>
    </div>
  );
};
