export const findScrollableParent = (element) => {
  let currentElement = element;

  while (currentElement) {
    if (
      currentElement.scrollHeight > currentElement.clientHeight ||
      currentElement.scrollWidth > currentElement.clientWidth
    ) {
      // Check computed styles to ensure the element is not set to `overflow: hidden`
      const computedStyle = window.getComputedStyle(currentElement);
      if (
        computedStyle.overflowY === "auto" ||
        computedStyle.overflowY === "scroll" ||
        computedStyle.overflowX === "auto" ||
        computedStyle.overflowX === "scroll"
      ) {
        return currentElement;
      }
    }

    // Move up the DOM tree
    currentElement = currentElement.parentElement;
  }

  // No scrollable parent found
  return null;
};
